//###########################################################################
// Button
//###########################################################################

.btn {
  text-decoration: none;
  text-align: center;
  font-size: 2rem;
  line-height: 1.2;
  font-family: $heading-font;
  font-weight: 500;
  outline: none;
  padding: 12px 30px;
  display: inline-block;
  cursor: pointer;
  transition: all .3s;
  user-select: none;
  border: none;
  border-radius: 30px;
  background-color: $primary-main;
  color: palette(black, font);

  @include breakpoint(medium) {
    &:hover, &:focus {
      background-color: $primary-main-dark;
      color: palette(black, font);
    }
  }
}


//###########################################################################
// Button modifiers
//###########################################################################

// Theme buttons
//------------------

.btn--primary-secondary {
  background-color: $primary-secondary;
  color: palette(black, font);

  @include breakpoint(medium) {
    &:hover, &:focus {
      background-color: lighten($primary-secondary, 10%);
      color: palette(black, font);
    }
  }
}

.btn--primary-soft {
  background-color: $primary-soft;
  color: palette(black, font);

  @include breakpoint(medium) {
    &:hover, &:focus {
      background-color: lighten($primary-soft, 10%);
      color: palette(black, font);
    }
  }
}

.btn--primary-dark {
  background-color: $primary-main-dark;
  color: palette(white);

  @include breakpoint(medium) {
    &:hover, &:focus {
      background-color: lighten($primary-main-dark, 10%);
      color: palette(white);
    }
  }
}

// Colours
//------------------

.btn--white {
  background-color: palette(white);
  color: palette(black, font);

  @include breakpoint(medium) {
    &:hover, &:focus {
      background-color: darken(palette(white), 10%);
      color: palette(black, font);
    }
  }
}

.btn--white-outline {
  background-color: transparent;
  border: 2px solid white;

  @include breakpoint(medium) {
    &:hover, &:focus {
      background-color: darken(palette(white), 10%);
      color: palette(black, font);
    }
  }
}

.btn--black {
  background-color: palette(black, font);
  color: palette(white);

  @include breakpoint(medium) {
    &:hover, &:focus {
      background-color: lighten(palette(black, font), 10%);
      color: palette(white);
    }
  }
}

// Sizes
//------------------

.btn--small {
  font-size: 1.7rem;
  line-height: 2.3rem;
  padding: 7px 20px;
}

.btn--large {
  font-size: 2.8rem;
  line-height: 3.3rem;
  padding: 14px 40px;
}