.questa-sans {
  font-family: questa-sans, sans-serif;
  font-weight: 900;
  font-style: normal; }

.gibson-book {
  font-family: canada-type-gibson, sans-serif;
  font-weight: 300;
  font-style: normal; }

.gibson-medium {
  font-family: canada-type-gibson, sans-serif;
  font-weight: 500;
  font-style: normal; }

.gibson-semi {
  font-family: canada-type-gibson, sans-serif;
  font-weight: 600;
  font-style: normal; }

.is-loading {
  animation: isLoading 1.5s infinite ease-in-out; }

@keyframes isLoading {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

.bg--cover {
  background-size: 100%;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center; }

.bg--white {
  background-color: #ffffff !important; }

.bg--black {
  background-color: #333333 !important; }

.bg--orange {
  background-color: #EA8A5D !important; }

.bg--orange-light {
  background-color: #EEAA79 !important; }

.bg--orange-lighter {
  background-color: #FFEEE2 !important; }

.bg--yellow-lighter {
  background-color: #FFF6E5 !important; }

.bg--green {
  background-color: #8A9366 !important; }

.bg--green-light {
  background-color: #CEDBAD !important; }

.bg--green-lighter {
  background-color: #F8FFED !important; }

.bg--primary-main {
  background-color: #A7D5C2 !important; }

.bg--primary-secondary {
  background-color: #74A68E !important; }

.bg--primary-soft {
  background-color: #E4FFF4 !important; }

.bg--primary-lighter {
  background-color: #E4FFF4 !important; }

.bg--primary-swoop, .bg--primary-swoop-reverse {
  position: relative;
  background-color: #74A68E;
  overflow: hidden; }
  .bg--primary-swoop .wrapper, .bg--primary-swoop-reverse .wrapper {
    z-index: 1; }
  .bg--primary-swoop:after, .bg--primary-swoop-reverse:after {
    content: '';
    position: absolute;
    bottom: 280px;
    left: 50%;
    transform: translateX(-50%);
    width: 3200px;
    height: 2200px;
    border-radius: 50%;
    background-color: #A7D5C2; }

.bg--primary-swoop-reverse {
  background-color: #A7D5C2; }
  .bg--primary-swoop-reverse:after {
    background-color: #74A68E; }

.bg--primary-lighter-xlarge, .bg--primary-lighter-large, .bg--primary-lighter-medium {
  position: relative;
  background-color: #ffffff; }
  .bg--primary-lighter-xlarge:before, .bg--primary-lighter-large:before, .bg--primary-lighter-medium:before {
    content: '';
    position: absolute;
    width: 100%;
    background-color: #E4FFF4;
    top: 0;
    left: 0; }
  .bg--primary-lighter-xlarge.bg--reverse, .bg--primary-lighter-large.bg--reverse, .bg--primary-lighter-medium.bg--reverse {
    background-color: #E4FFF4; }
    .bg--primary-lighter-xlarge.bg--reverse:before, .bg--primary-lighter-large.bg--reverse:before, .bg--primary-lighter-medium.bg--reverse:before {
      background-color: #ffffff; }

.bg--primary-lighter-medium:before {
  height: 220px; }

.bg--primary-lighter-large:before {
  height: 300px; }

.bg--primary-lighter-xlarge:before {
  height: 400px; }

@media (max-width: 991px) {
  .bg--primary-lighter-medium--medium-down:before {
    height: 220px; } }

.bg--green-lighter-large, .bg--green-lighter-medium {
  position: relative;
  background-color: #ffffff; }
  .bg--green-lighter-large:before, .bg--green-lighter-medium:before {
    content: '';
    position: absolute;
    width: 100%;
    background-color: #F8FFED;
    top: 0;
    left: 0; }
  .bg--green-lighter-large.bg--reverse, .bg--green-lighter-medium.bg--reverse {
    background-color: #F8FFED; }
    .bg--green-lighter-large.bg--reverse:before, .bg--green-lighter-medium.bg--reverse:before {
      background-color: #ffffff; }

.bg--green-lighter-medium:before {
  height: 220px; }

.bg--green-lighter-large:before {
  height: 300px; }

.bg--orange-lighter-large, .bg--orange-lighter-medium {
  position: relative;
  background-color: #ffffff; }
  .bg--orange-lighter-large:before, .bg--orange-lighter-medium:before {
    content: '';
    position: absolute;
    width: 100%;
    background-color: #FFEEE2;
    top: 0;
    left: 0; }
  .bg--orange-lighter-large.bg--reverse, .bg--orange-lighter-medium.bg--reverse {
    background-color: #FFEEE2; }
    .bg--orange-lighter-large.bg--reverse:before, .bg--orange-lighter-medium.bg--reverse:before {
      background-color: #ffffff; }

.bg--orange-lighter-medium:before {
  height: 220px; }

.bg--orange-lighter-large:before {
  height: 300px; }

main, .wrapper {
  *zoom: 1; }
  main:before, .wrapper:before, main:after, .wrapper:after {
    content: " ";
    display: table; }
  main:after, .wrapper:after {
    clear: both; }

.clear {
  clear: both; }
  .clear:after {
    content: "";
    display: table;
    clear: both; }

.color--white {
  color: #ffffff !important; }

.color--black {
  color: #333333 !important; }

.color--orange {
  color: #EA8A5D !important; }

.color--orange-light {
  color: #EEAA79 !important; }

.color--green {
  color: #8A9366 !important; }

.color--green-light {
  color: #CEDBAD !important; }

.color--primary {
  color: #A7D5C2 !important; }

.color--primary-dark {
  color: #60927b !important; }

.cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.flex {
  display: flex; }

.justify-content-center {
  justify-content: center; }

.justify-content-between {
  justify-content: space-between; }

.align-items-center {
  align-items: center; }

.flex-wrap {
  flex-wrap: wrap; }

.gap-sm {
  gap: 12px; }

.gap-md {
  gap: 20px; }

.gap-lg {
  gap: 32px; }

.gap-xl {
  gap: 40px; }

.grow {
  flex-grow: 1; }

@media (max-width: 767px) {
  .justify-content-center--small-down {
    justify-content: center; } }

.is-hidden {
  display: none; }

@media (min-width: 768px) {
  .is-hidden--sm {
    display: none !important; } }

@media (min-width: 992px) {
  .is-hidden--md {
    display: none !important; } }

.hidden {
  display: none !important; }

.is-visible {
  display: block; }

.is-visible--md {
  display: none; }
  @media (min-width: 992px) {
    .is-visible--md {
      display: block !important; } }

.iframe-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0; }
  .iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 100%; }

.iframe-container--3-2 {
  padding-bottom: 66.66% !important; }

@media (max-width: 767px) {
  .iframe-container--3-2-small-down {
    padding-bottom: 66.66% !important; } }

.img--responsive {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }

.img--background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-family: 'object-fit: cover;'; }

.img--rounded {
  border-radius: 6px; }

.img--shadow {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2); }

.bump {
  margin-bottom: 20px !important; }

.bump--sm {
  margin-bottom: 40px !important; }

.bump--md {
  margin-bottom: 60px !important; }

.bump--none {
  margin-bottom: 0 !important; }

.bump--hero {
  margin-bottom: 400px !important; }

@media (max-width: 767px) {
  .bump--small-down {
    margin-bottom: 20px !important; }
  .bump--sm-small-down {
    margin-bottom: 40px !important; } }

@media (max-width: 991px) {
  .bump--medium-down {
    margin-bottom: 20px !important; }
  .bump--sm-medium-down {
    margin-bottom: 40px !important; } }

.nudge {
  margin-right: 15px !important; }

@media (min-width: 1260px) {
  .p-nudge-lg-large-up {
    padding-right: 45px !important; } }

.pullup--intro {
  margin-top: -5vw; }

.pullup {
  margin-top: -250px; }

.pullup--lg {
  margin-top: -435px; }

.spacer {
  margin-bottom: 8vh; }
  .spacer--large {
    margin-bottom: 8vh; }
    @media (min-width: 768px) {
      .spacer--large {
        margin-bottom: 15vh; } }
  .spacer-top {
    margin-top: 8vh; }
    @media (min-height: 1500px) {
      .spacer-top {
        margin-top: 4vh; }
        .spacer-top-top {
          margin-top: 4vh; } }
    .spacer-top--large {
      margin-top: 8vh; }
      @media (min-width: 768px) {
        .spacer-top--large {
          margin-top: 15vh; } }
  @media (min-height: 1500px) {
    .spacer {
      margin-bottom: 4vh; }
      .spacer-top {
        margin-top: 4vh; } }

.p-spacer {
  padding-bottom: 8vh; }
  .p-spacer--large {
    padding-bottom: 8vh; }
    @media (min-width: 768px) {
      .p-spacer--large {
        padding-bottom: 15vh; } }
  .p-spacer-top {
    padding-top: 8vh; }
    .p-spacer-top--large {
      padding-top: 8vh; }
      @media (min-width: 768px) {
        .p-spacer-top--large {
          padding-top: 15vh; } }
  @media (min-height: 1500px) {
    .p-spacer {
      padding-bottom: 4vh; }
      .p-spacer-top {
        padding-top: 4vh; } }

.p-spacer-about-services {
  padding-bottom: 380px; }

.tt-u {
  text-transform: uppercase !important; }

.bold {
  font-weight: 700 !important; }

.normal, .normal > p {
  font-weight: 400 !important; }

.medium {
  font-weight: 500 !important; }

.light, .light > p {
  font-weight: 300 !important; }

em, .italic, i {
  font-style: italic !important; }

.td-n {
  text-decoration: none !important; }

.td-u {
  text-decoration: underline !important; }

.ta-c {
  text-align: center !important; }

.ta-l {
  text-align: left !important; }

.ta-r {
  text-align: right !important; }

@media (max-width: 767px) {
  .ta-c--small-down {
    text-align: center !important; } }

@media (min-width: 768px) {
  .ta-r--small-up {
    text-align: right !important; } }

@media (min-width: 992px) {
  .ta-r--medium-up {
    text-align: right !important; } }

.br {
  display: block; }

.a11y {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
  position: absolute !important;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden; }

.mw--280 {
  max-width: 280px; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

a {
  color: inherit; }

textarea {
  resize: none; }

html {
  height: 100%;
  max-width: 100%;
  font-size: 55% !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  min-height: 100% !important;
  background: #ffffff;
  scroll-behavior: smooth; }
  @media (min-width: 1260px) {
    html {
      font-size: 62.5% !important;
      /* Normalise font size so that 1rem = 10px, 2rem = 20px etc. */ } }

body {
  position: relative; }
  body.fix {
    overflow: hidden; }

main {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 61px; }
  @media (min-width: 992px) {
    main {
      padding-top: 157px; } }
  @media (min-width: 1260px) {
    main {
      padding-top: 157px; } }
  main.main--home {
    padding-top: 61px; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4 {
  font-family: questa-sans, sans-serif;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 32px; }

h1, .h1 {
  font-size: 4.5rem; }

h2, .h2 {
  font-size: 4rem; }

h3, .h3 {
  font-size: 3rem; }

h4, .h4 {
  font-size: 2.4rem; }

body {
  font-family: canada-type-gibson;
  font-style: normal;
  font-weight: 300;
  font-size: 1.8rem;
  line-height: 1.7;
  color: #223343; }

p, .p {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  line-height: inherit;
  margin-bottom: 20px; }

.small > p,
.small > .p,
.small {
  font-size: 1.4rem !important; }

.big > p,
.big > .p,
.big {
  font-size: 2.2rem !important; }

strong {
  font-weight: bold; }

em {
  font-style: italic; }

ul, ol {
  margin-left: 20px;
  padding-top: 10px;
  padding-bottom: 25px; }
  ul.ul--unstyled, ul.ul--row, ol.ul--unstyled, ol.ul--row {
    list-style: none;
    margin: 0;
    padding: 0; }
    ul.ul--unstyled li, ul.ul--row li, ol.ul--unstyled li, ol.ul--row li {
      padding: 0 0 15px; }
  ul.ul--row li, ol.ul--row li {
    display: inline-block;
    vertical-align: middle; }

ul {
  list-style: disc; }

ol {
  list-style: decimal; }

li {
  font-size: inherit;
  line-height: 1.5;
  font-weight: 300;
  color: inherit;
  padding-bottom: 15px; }

/*
  NOTE

	This file should only contain css related to the grid
	just widths, margins and vertical spacing,
	Any other styling, such as background colours, should be moved to a different
	selector. Nount wrong with 4/5 classes on a single element.
	<div class="g__col gm__1-2 gt__1-3 gd__1-4 cta-box"></div>
	Ideally, if you can, move the cta styling to a child element, leaving the class
	with only grid properties. Oh so reusable, oh so portable
*/
.g {
  width: 100%;
  lost-flex-container: row; }
  .g > * {
    width: 100%; }

.g--ng > * {
  width: 50%;
  flex-basis: 50%; }

/*
  Footer grid modification
*/
.g--gutter .g__1-2 {
  lost-column: 1/2 2 1.33334rem; }

.g--gutter .g__1-3 {
  lost-column: 1/3 3 1.33334rem; }

.g--gutter .g__2-3 {
  lost-column: 2/3 3 1.33334rem; }

@media (min-width: 768px) {
  .gs__ac {
    align-items: center; }
  .g--gutter .gs__1-2 {
    lost-column: 1/2 2 1.33334rem; }
  .gs__1-2 {
    lost-column: 1/2; }
  .gs__2-2 {
    lost-column: 2/2; }
  .gs__m1-2 {
    lost-move: 1/2; }
  .gs__m-1-2 {
    lost-move: -1/2; }
  .gs__1-3 {
    lost-column: 1/3; }
  .gs__2-3 {
    lost-column: 2/3; }
  .gs__m1-3 {
    lost-move: 1/3; }
  .gs__m2-3 {
    lost-move: 2/3; }
  .gs__m-1-3 {
    lost-move: -1/3; } }

@media (min-width: 992px) {
  .g--modal .gm__1-2 {
    lost-column: 1/2 2 15px; }
  .gm__ac {
    align-items: center; }
  .g--gutter .gm__1-2 {
    lost-column: 1/2 2 2rem; }
  .gm__1-2 {
    lost-column: 1/2; }
  .gm__1-3 {
    lost-column: 1/3; }
  .gm__2-3 {
    lost-column: 2/3; }
  .gm__1-4 {
    lost-column: 1/4; }
  .gm__2-4 {
    lost-column: 2/4; }
  .gm__3-4 {
    lost-column: 3/4; }
  .gm__m3-4 {
    lost-move: 3/4; }
  .gm__m-1-4 {
    lost-move: -1/4; } }

@media (min-width: 1260px) {
  .g--gutter .gl__1-3 {
    lost-column: 1/3 3 2rem; }
  .gl__1-3 {
    lost-column: 1/3; } }

.wrapper {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding: 0 1.33334rem; }
  @media (min-width: 768px) {
    .wrapper {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .wrapper {
      max-width: 940px; } }
  @media (min-width: 1260px) {
    .wrapper {
      max-width: 1260px; } }

@media (min-width: 1260px) {
  .wrapper--tight {
    max-width: 960px; } }

@media (min-width: 992px) {
  .wrapper--x-tight {
    max-width: 770px; } }

@media (min-width: 768px) {
  .wrapper--full {
    max-width: 100%; } }

#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  transition: transform .3s, background-color .3s; }
  #header .show-desktop, #header .promo, #header .top-menu {
    display: none; }
  #header a {
    text-decoration: none; }
  #header .site-header {
    position: relative;
    z-index: 3;
    background-color: #A7D5C2;
    height: 61px; }
    #header .site-header .site-header__inner {
      display: flex;
      align-items: center;
      justify-content: space-between; }
  #header .logo {
    display: inline-block; }
    #header .logo a {
      display: block;
      font-size: 0; }
    #header .logo .aem-logo {
      width: 190px;
      height: 30px; }
    #header .logo .shs-logo {
      width: 224px;
      height: 30px; }
  #header .nav {
    list-style: none; }
    #header .nav > li > a, #header .nav > li > span {
      font-weight: bold; }
  #header .nav__icon-group .site-nav__search {
    display: block; }
  #header .nav__icon-group li {
    padding-bottom: 0; }
    #header .nav__icon-group li:first-of-type {
      margin-right: 15px; }
  #header .site-nav__search {
    width: 30px;
    height: 30px;
    margin: 14px 0;
    transition: background .3s; }
  #header .search__hold {
    background-color: #E4FFF4;
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 11;
    top: 61px;
    height: calc(100vh - 56px);
    transition: opacity .3s, visibility .3s;
    opacity: 0;
    visibility: hidden;
    overflow: auto;
    padding: 80px 0; }
  #header .search__close {
    background-color: #A7D5C2;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    top: -60px;
    right: 8px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (min-width: 768px) {
      #header .search__close {
        right: 15px; } }
    @media (min-width: 992px) {
      #header .search__close {
        transform: translateY(0); } }
  #header .search__close__wrapper {
    position: relative; }

.search {
  position: relative; }
  .search input {
    border: none;
    width: calc(100% - 60px);
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px; }
    .search input:focus {
      background: #fafafa; }
  .search .submit {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    border: none;
    width: 80px;
    background: #A7D5C2;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer; }

.search__hold .popular, .error__hold .popular {
  font-weight: bold;
  border-bottom: 1px solid #223343;
  padding-bottom: 5px; }

.search__hold li, .error__hold li {
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 0; }

.search__hold .btn, .error__hold .btn {
  font-weight: 300;
  font-family: canada-type-gibson;
  border-radius: 8px;
  color: #223343;
  background-color: #74A68E; }

.header--shs .search__hold .btn, .header--shs .error__hold .btn {
  background-color: #A7D5C2; }

#header.header--search .search__hold {
  opacity: 1;
  visibility: visible; }

#header.header--scrolled {
  background-color: #ffffff;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2); }

#header.header--no-shadow {
  box-shadow: none; }

@media (max-width: 400px) {
  #header .logo {
    display: inline-block; }
    #header .logo .aem-logo {
      max-width: 160px; }
    #header .logo .shs-logo {
      max-width: 175px; } }

@media (max-width: 991px) {
  #header .show-inline-desktop {
    display: none; }
  #header .logo svg path {
    fill: white !important; }
  #header .site-nav {
    background-color: white;
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 11;
    top: 61px;
    height: 100vh;
    transition: transform .3s;
    transform: translateX(-100%);
    overflow-y: scroll;
    overflow-x: hidden; }
  #header .nav {
    margin: 0;
    padding: 30px 20px 100px 20px; }
    #header .nav > li {
      padding: 0;
      margin-bottom: 15px; }
      #header .nav > li > a, #header .nav > li > span {
        display: block;
        font-size: 2.2rem; }
  #header .nav.nav--aem .sub-nav__hold > a, #header .nav.nav--aem .sub-nav__hold > span, #header .nav.nav--aem .sub-nav-dropdown-heading {
    background-image: url("/dist/img/icons/nav-menu-arrow-right-aem.svg"); }
  #header .nav.nav--aem .sub-nav__back {
    background-image: url("/dist/img/icons/nav-menu-arrow-left-aem.svg"); }
  #header .nav.nav--shs .sub-nav__hold > a, #header .nav.nav--shs .sub-nav__hold > span, #header .nav.nav--shs .sub-nav-dropdown-heading {
    background-image: url("/dist/img/icons/nav-menu-arrow-right-shs.svg"); }
  #header .nav.nav--shs .sub-nav__back {
    background-image: url("/dist/img/icons/nav-menu-arrow-left-shs.svg"); }
  #header .sub-nav__hold > a, #header .sub-nav__hold > span {
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: center right; }
  #header .list--about .sub-nav-heading {
    background-color: #D0938C; }
  #header .list--as .sub-nav-heading {
    background-color: #EEAA79; }
  #header .list--cs .sub-nav-heading {
    background-color: #CEDBAD; }
  #header .list--training .sub-nav-heading {
    background-color: #F5DBA8; }
  #header .list--school .sub-nav-heading {
    background-color: #A7D5C2; }
  #header .sub-nav {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    transform: translateX(100%);
    transition: transform .3s; }
    #header .sub-nav .sub-nav__inner {
      padding: 30px 20px; }
    #header .sub-nav ul {
      padding: 0;
      margin: 0;
      list-style: none; }
    #header .sub-nav .sub-nav-heading {
      padding: 10px;
      border-radius: 8px;
      font-size: 2.2rem;
      font-weight: bold;
      margin-bottom: 10px; }
    #header .sub-nav .sub-nav-dropdown-heading {
      font-weight: 500;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 0;
      font-size: 1.8rem;
      background-size: 22px;
      background-repeat: no-repeat;
      background-position: center right; }
    #header .sub-nav .sub-nav-dropdown {
      position: absolute;
      padding: 30px 20px 100px;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: white;
      transform: translateX(100%);
      transition: transform .3s; }
    #header .sub-nav li.open .sub-nav-dropdown {
      transform: translateX(0%); }
    #header .sub-nav li {
      padding: 0; }
      #header .sub-nav li > a, #header .sub-nav li > span {
        display: block;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 25px;
        font-size: 1.8rem;
        font-weight: 500; }
  #header .sub-nav__back {
    font-size: 1.8rem;
    padding-left: 35px;
    font-weight: 400;
    background-image: url("/dist/img/icons/arrow-left-white.svg");
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: center left;
    margin-bottom: 20px;
    cursor: pointer; }
  #header .open .sub-nav {
    transform: translateX(0); }
  #header.header--open .site-nav {
    transform: translateX(0); } }

@media (min-width: 992px) {
  .search input {
    padding: 30px 20px;
    font-size: 2rem; }
  #header.header--home .site-header {
    background-color: transparent; }
  #header.header--home:hover {
    background: #ffffff; }
  #header.header--scrolled .search__hold {
    top: 96px;
    height: 100vh; }
  #header .search__hold {
    top: 62px;
    height: calc(100vh - 62px); }
  #header .site-nav__search {
    padding: 0; }
  #header .top-menu, #header .show-desktop {
    display: block; }
  #header .top-menu {
    height: 62px;
    transition: all 0.3s;
    background-color: #A7D5C2; }
    #header .top-menu li {
      margin-right: 35px;
      padding-bottom: 0; }
      #header .top-menu li:last-of-type {
        margin-right: 0; }
    #header .top-menu p {
      margin-top: 18px;
      margin-bottom: 21px; }
    #header .top-menu p a {
      color: white;
      font-size: 1.6rem;
      font-weight: 500; }
      #header .top-menu p a:hover {
        text-decoration: underline; }
    #header .top-menu .btn {
      margin-top: 1px;
      margin-bottom: 1px;
      color: #A7D5C2; }
  #header.header--shs .top-menu {
    background-color: #74A68E; }
  #header .show-inline-desktop {
    display: inline-block;
    padding: 0 !important; }
  #header .nav__icon-group, #header .hide-desktop {
    display: none; }
  #header .logo {
    display: inline-block; }
    #header .logo .aem-logo {
      width: 209px;
      height: 33px; }
    #header .logo .shs-logo {
      width: 261px;
      height: 35px; }
  #header .site-header {
    background-color: white;
    height: 96px; }
  #header .nav > li {
    padding: 36px 14px; }
    #header .nav > li > a, #header .nav > li > span {
      font-weight: 500;
      line-height: 1; }
    #header .nav > li:hover, #header .nav > li.active {
      background-color: #F3F3F3; }
  #header .sub-nav__hold {
    position: relative;
    padding: 36px 18px 36px 8px !important; }
    #header .sub-nav__hold > a, #header .sub-nav__hold > span {
      position: relative; }
      #header .sub-nav__hold > a::before, #header .sub-nav__hold > span::before {
        content: "";
        position: absolute;
        background-image: url("/dist/img/icons/arrow-down-nav.svg");
        background-size: 11px 6px;
        background-repeat: no-repeat;
        background-position: center left;
        width: 11px;
        height: 6px;
        right: -14px;
        top: 8px;
        transition: transform .3s; }
    #header .sub-nav__hold:hover {
      background-color: #F3F3F3; }
      #header .sub-nav__hold:hover > a::before, #header .sub-nav__hold:hover > span::before {
        transform: rotate(180deg); }
      #header .sub-nav__hold:hover .sub-nav {
        opacity: 1;
        visibility: visible; }
  #header .sub-nav {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 100%;
    width: 390px;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s, visibility .3s; }
    #header .sub-nav a {
      font-weight: 500;
      display: block;
      padding: 10px;
      border-radius: 8px; }
    #header .sub-nav .list--about a:hover, #header .sub-nav .list--about .extra-sub-nav__hold:hover > a {
      background-color: #D0938C; }
    #header .sub-nav .list--as a:hover, #header .sub-nav .list--as .extra-sub-nav__hold:hover > a {
      background-color: #EEAA79; }
    #header .sub-nav .list--cs a:hover, #header .sub-nav .list--cs .extra-sub-nav__hold:hover > a {
      background-color: #CEDBAD; }
    #header .sub-nav .list--training a:hover, #header .sub-nav .list--training .extra-sub-nav__hold:hover > a {
      background-color: #F5DBA8; }
    #header .sub-nav .list--school a:hover, #header .sub-nav .list--school .extra-sub-nav__hold:hover > a {
      background-color: #A7D5C2; }
  #header .sub-nav__inner {
    background: #FFFFFF;
    box-shadow: 0 35px 30px 0 rgba(0, 0, 0, 0.25);
    border-radius: 0 0 8px 8px;
    position: relative; }
  #header .sub-nav-heading {
    font-weight: bold;
    font-size: 2.2rem;
    padding-left: 12px; }
  #header .sub-nav__hold--last .sub-nav {
    left: initial;
    transform: translateX(0);
    right: 0; }
  #header .sub-nav--double,
  #header .sub-nav--triple {
    transform: translateX(0%); }
    #header .sub-nav--double .sub-nav__inner,
    #header .sub-nav--triple .sub-nav__inner {
      display: flex;
      min-height: 460px; }
    #header .sub-nav--double .list,
    #header .sub-nav--triple .list {
      flex: 1;
      padding: 45px 0 45px 15px; }
    #header .sub-nav--double .promo,
    #header .sub-nav--triple .promo {
      display: block;
      flex: 1;
      width: 33.333%;
      background: white;
      position: relative;
      transition: opacity .3s;
      padding: 10px; }
      #header .sub-nav--double .promo .overlay,
      #header .sub-nav--triple .promo .overlay {
        height: 100%;
        padding: 30px 50px 30px 20px;
        border-radius: 8px;
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: 100%; }
      #header .sub-nav--double .promo p a,
      #header .sub-nav--triple .promo p a {
        padding: 0;
        font-weight: 400;
        text-decoration: underline; }
        #header .sub-nav--double .promo p a:hover,
        #header .sub-nav--triple .promo p a:hover {
          opacity: .6; }
      #header .sub-nav--double .promo--as .overlay,
      #header .sub-nav--triple .promo--as .overlay {
        background-color: #EEAA79;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 370 127.3' style='enable-background:new 0 0 370 127.3' xml:space='preserve'%3E%3Cpath d='M0 86.1v41.2h370V10C260.6 44.8 135.4 71 0 86.1z' style='fill-rule:evenodd;clip-rule:evenodd;fill:%23EA8A5D'/%3E%3C/svg%3E"); }
      #header .sub-nav--double .promo--school .overlay,
      #header .sub-nav--triple .promo--school .overlay {
        background-color: #A7D5C2;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 370 127.3' style='enable-background:new 0 0 370 127.3' xml:space='preserve'%3E%3Cpath d='M0 86.1v41.2h370V10C260.6 44.8 135.4 71 0 86.1z' style='fill-rule:evenodd;clip-rule:evenodd;fill:%2374A68E'/%3E%3C/svg%3E"); }
  #header .sub-nav--double {
    width: 780px;
    left: -375px; }
    #header .sub-nav--double .promo {
      width: 50%; }
  #header .sub-nav--triple {
    width: 916px;
    left: -345px; }
    #header .sub-nav--triple .promo {
      width: 33.333%; }
    #header .sub-nav--triple .sub-nav-heading--no-desktop {
      display: none; }
    #header .sub-nav--triple .sub-nav-dropdown {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      background: white;
      left: 0;
      width: 33.333%;
      margin-left: 33.333%;
      padding-top: 97px;
      padding-left: 10px;
      padding-right: 5px;
      z-index: 3; }
    #header .sub-nav--triple .sub-nav-category {
      background: white;
      border-radius: 8px; }
    #header .sub-nav--triple .extra-sub-nav__hold > a, #header .sub-nav--triple .extra-sub-nav__hold > span {
      background-image: url("/dist/img/icons/arrow-right-nav.svg");
      background-size: 7px 12px;
      background-repeat: no-repeat;
      background-position: center right 15px; }
    #header .sub-nav--triple .extra-sub-nav__hold:hover .sub-nav-dropdown {
      display: block; }
  #header .sub-nav__back {
    display: none; }
  #header .list {
    text-align: left;
    padding: 45px 15px 20px; }
    #header .list li {
      display: block;
      padding-bottom: 5px; }
      #header .list li:last-of-type {
        padding-bottom: 0; }
  #header nav ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  #header nav li {
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 0; } }

@media (min-width: 1260px) {
  #header .nav > li {
    padding: 34px 20px; }
  #header .sub-nav__hold {
    padding: 34px 28px 34px 14px !important; }
    #header .sub-nav__hold > a::before {
      right: -18px; }
  #header .sub-nav--triple {
    width: 1233px;
    left: -521px; } }

.footer {
  overflow: hidden;
  position: relative;
  z-index: 1; }
  .footer .curve {
    position: relative;
    top: 2px;
    font-size: 0; }
    .footer .curve img {
      transform: translateX(-1%);
      width: 102%; }
  @media (max-width: 400px) {
    .footer .logo-contact a {
      font-size: 1.8rem; } }
  .footer .logo {
    max-width: 270px;
    width: 100%;
    margin-bottom: 30px; }
  .footer .inner {
    background-color: #A7D5C2;
    padding-top: 30px;
    padding-bottom: 40px; }
    .footer .inner p, .footer .inner a {
      color: #223343; }
    .footer .inner a {
      text-decoration: none;
      opacity: 1;
      transition: all 0.2s; }
      .footer .inner a:hover {
        opacity: 0.65; }
  .footer .ul--row li {
    margin-right: 25px; }
    .footer .ul--row li:last-of-type {
      margin-right: 0; }
  .footer .copy {
    padding-top: 20px;
    background-color: #74A68E; }
    .footer .copy p {
      font-size: 1.4rem; }
  .footer--pullup {
    margin-top: -5.25vw; }

@media (min-width: 768px) {
  .footer .logo, .footer .contact p {
    margin-bottom: 0; }
  .footer .logo-contact {
    display: flex;
    align-items: center;
    justify-content: space-between; } }

body.debug:after {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  text-transform: uppercase;
  padding: 17px 25px;
  position: fixed;
  font-size: 15px;
  font-weight: 700;
  bottom: 45px;
  right: 0;
  z-index: 999; }
  @media (min-width: x-small) {
    body.debug:after {
      content: "x-small"; } }
  @media (min-width: 768px) {
    body.debug:after {
      content: "small"; } }
  @media (min-width: 992px) {
    body.debug:after {
      content: "medium"; } }
  @media (min-width: 1260px) {
    body.debug:after {
      content: "large"; } }

.article__information {
  margin-top: 65px;
  margin-bottom: 30px; }
  @media (min-width: 420px) {
    .article__information {
      margin-top: 25px; } }
  .article__information li {
    padding-bottom: 0; }
  .article__information p {
    margin-bottom: 0; }

.article__footer {
  padding: 32px 0;
  margin: 32px 0;
  border-top: 1px solid #D1DDE6;
  border-bottom: 1px solid #D1DDE6; }

.btn {
  text-decoration: none;
  text-align: center;
  font-size: 2rem;
  line-height: 1.2;
  font-family: questa-sans, sans-serif;
  font-weight: 500;
  outline: none;
  padding: 12px 30px;
  display: inline-block;
  cursor: pointer;
  transition: all .3s;
  user-select: none;
  border: none;
  border-radius: 30px;
  background-color: #A7D5C2;
  color: #223343; }
  @media (min-width: 992px) {
    .btn:hover, .btn:focus {
      background-color: #60927b;
      color: #223343; } }

.btn--primary-secondary {
  background-color: #74A68E;
  color: #223343; }
  @media (min-width: 992px) {
    .btn--primary-secondary:hover, .btn--primary-secondary:focus {
      background-color: #93baa7;
      color: #223343; } }

.btn--primary-soft {
  background-color: #E4FFF4;
  color: #223343; }
  @media (min-width: 992px) {
    .btn--primary-soft:hover, .btn--primary-soft:focus {
      background-color: white;
      color: #223343; } }

.btn--primary-dark {
  background-color: #60927b;
  color: #ffffff; }
  @media (min-width: 992px) {
    .btn--primary-dark:hover, .btn--primary-dark:focus {
      background-color: #7ca994;
      color: #ffffff; } }

.btn--white {
  background-color: #ffffff;
  color: #223343; }
  @media (min-width: 992px) {
    .btn--white:hover, .btn--white:focus {
      background-color: #e6e6e6;
      color: #223343; } }

.btn--white-outline {
  background-color: transparent;
  border: 2px solid white; }
  @media (min-width: 992px) {
    .btn--white-outline:hover, .btn--white-outline:focus {
      background-color: #e6e6e6;
      color: #223343; } }

.btn--black {
  background-color: #223343;
  color: #ffffff; }
  @media (min-width: 992px) {
    .btn--black:hover, .btn--black:focus {
      background-color: #334d65;
      color: #ffffff; } }

.btn--small {
  font-size: 1.7rem;
  line-height: 2.3rem;
  padding: 7px 20px; }

.btn--large {
  font-size: 2.8rem;
  line-height: 3.3rem;
  padding: 14px 40px; }

.breadcrumb {
  display: none; }
  .breadcrumb a {
    text-decoration: none;
    color: #223343; }
  .breadcrumb li {
    color: #223343;
    margin-right: 8px;
    font-size: 1.4rem;
    font-weight: 300; }
    .breadcrumb li:first-of-type {
      margin-left: 0; }
    .breadcrumb li a {
      font-size: 1.4rem;
      font-weight: 300;
      opacity: 1;
      transition: opacity 0.2s; }
      .breadcrumb li a:hover {
        opacity: 0.5; }
  @media (min-width: 768px) {
    .breadcrumb {
      display: block; } }
  .breadcrumb--dark a {
    color: #223343; }
  .breadcrumb--dark li {
    color: #223343; }

.calendar__list {
  background: #ffffff;
  box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding-right: 4px;
  padding-bottom: 12px; }
  .calendar__list table {
    width: 100%; }
    @media (max-width: 767px) {
      .calendar__list table thead {
        display: none; } }
    .calendar__list table thead tr {
      width: calc(100% - 0.4rem); }
      @media (min-width: 1260px) {
        .calendar__list table thead tr {
          width: calc(100% - 0.5rem); } }
      .calendar__list table thead tr th {
        padding: 16px 20px;
        font-size: 2.2rem;
        font-weight: bold;
        text-align: left; }
    .calendar__list table tr {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .calendar__list table tr td {
        padding: 16px 20px;
        font-size: 1.8rem;
        text-align: left; }
        @media (max-width: 767px) {
          .calendar__list table tr td {
            display: block; } }
        @media (min-width: 768px) {
          .calendar__list table tr td:not(:last-child) {
            border-right: 2px solid #8A9366; } }
      .calendar__list table tr.highlight {
        animation: highlightRow 0.75s;
        animation-delay: 200ms;
        animation-timing-function: cubic-bezier(0, 0, 0.58, 1); }

@keyframes highlightRow {
  0% {
    background: #ffffff; }
  50% {
    background: #CEDBAD; }
  100% {
    background: #ffffff; } }

@keyframes highlightRowShs {
  0% {
    background: #ffffff; }
  50% {
    background: #E4FFF4; }
  100% {
    background: #ffffff; } }
    .calendar__list table tbody {
      display: block;
      width: 100%;
      max-height: 524px;
      overflow: auto;
      scroll-behavior: smooth; }
      .calendar__list table tbody::-webkit-scrollbar {
        width: 5px;
        height: 5px; }
      .calendar__list table tbody::-webkit-scrollbar-track {
        background: #e6e6e6;
        border-radius: 10px; }
      .calendar__list table tbody::-webkit-scrollbar-thumb {
        background-color: #8A9366;
        border-radius: 10px;
        border: 3px solid #8A9366; }

@media (max-width: 767px) {
  .calendar__event .calendar__event__date:before, .calendar__event .calendar__event__time:before, .calendar__event .calendar__event__title:before {
    display: inline;
    font-weight: bold; }
  .calendar__event .calendar__event__date:before {
    content: 'Date: '; }
  .calendar__event .calendar__event__time:before {
    content: 'Time: '; }
  .calendar__event .calendar__event__title:before {
    content: 'Event: '; }
  .calendar__event:not(:last-child) {
    border-bottom: 2px solid #CEDBAD; } }

.calendar__event.hidden {
  display: none; }

.calendar__aside {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px; }

.calendar__datepicker {
  position: relative; }
  .calendar__datepicker .qs-datepicker-container {
    top: 0 !important;
    left: 0 !important; }

.calendar__filter {
  padding: 35px 12px; }
  .calendar__filter fieldset legend {
    font-family: questa-sans, sans-serif !important;
    font-size: 2.2rem;
    margin-bottom: 16px; }
  .calendar__filter fieldset .calendar__filter__radio {
    margin-bottom: 6px; }
    .calendar__filter fieldset .calendar__filter__radio label {
      width: 100%; }

@media (max-width: 767px) {
  .calendar--shs .calendar__event:not(:last-child) {
    border-bottom: 2px solid #A7D5C2; } }

@media (min-width: 768px) {
  .calendar--shs .calendar__list table tr td:not(:last-child) {
    border-right: 2px solid #A7D5C2; } }

.calendar--shs .calendar__list table tr.highlight {
  animation: highlightRowShs 0.75s;
  animation-delay: 200ms;
  animation-timing-function: cubic-bezier(0, 0, 0.58, 1); }

.calendar--shs .calendar__list table tbody::-webkit-scrollbar-thumb {
  background-color: #A7D5C2;
  border-radius: 10px;
  border: 3px solid #A7D5C2; }

.calendar--shs .qs-datepicker-container .qs-controls .qs-arrow.qs-left {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 23 23'%3E%3Cpath fill='%23A7D5C2' d='M11.5 0C5.1 0 0 5.1 0 11.5S5.1 23 11.5 23 23 17.9 23 11.5 17.9 0 11.5 0zm2.3 17.8l-6.3-6.3.1-.1 1.5-1.5 4.5-4.5L15.3 7l-4.5 4.5 4.7 4.7-1.7 1.6z'%3E%3C/path%3E%3C/svg%3E"); }

.calendar--shs .qs-datepicker-container .qs-controls .qs-arrow.qs-right {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 23 23'%3E%3Cpath fill='%23A7D5C2' d='M11.5 23C17.9 23 23 17.9 23 11.5S17.9 0 11.5 0 0 5.1 0 11.5 5.1 23 11.5 23zM9.2 5.2l6.3 6.3-.1.1-1.5 1.5-4.5 4.5L7.7 16l4.5-4.5-4.7-4.7 1.7-1.6z'%3E%3C/path%3E%3C/svg%3E"); }

.calendar--shs .qs-datepicker-container .qs-event:after {
  border: 2px solid #A7D5C2; }

.calendar--shs .qs-datepicker-container .qs-active {
  background: transparent !important; }
  .calendar--shs .qs-datepicker-container .qs-active.qs-event:after {
    border: 2px solid #A7D5C2;
    background: #A7D5C2 !important; }

.datepicker {
  display: none; }

.qs-datepicker-container {
  position: unset !important;
  font-family: canada-type-gibson !important;
  font-size: 1.6rem !important;
  width: 100% !important;
  color: inherit !important;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important; }
  .qs-datepicker-container .qs-controls {
    background: transparent !important;
    padding: 15px 8px; }
    .qs-datepicker-container .qs-controls .qs-arrow {
      height: 20px;
      width: 20px; }
      .qs-datepicker-container .qs-controls .qs-arrow:hover {
        background-color: transparent !important; }
      .qs-datepicker-container .qs-controls .qs-arrow.qs-left {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 23 23'%3E%3Cpath fill='%238A9366' d='M11.5 0C5.1 0 0 5.1 0 11.5S5.1 23 11.5 23 23 17.9 23 11.5 17.9 0 11.5 0zm2.3 17.8l-6.3-6.3.1-.1 1.5-1.5 4.5-4.5L15.3 7l-4.5 4.5 4.7 4.7-1.7 1.6z'%3E%3C/path%3E%3C/svg%3E"); }
        .qs-datepicker-container .qs-controls .qs-arrow.qs-left:after {
          content: unset !important; }
      .qs-datepicker-container .qs-controls .qs-arrow.qs-right {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 23 23'%3E%3Cpath fill='%238A9366' d='M11.5 23C17.9 23 23 17.9 23 11.5S17.9 0 11.5 0 0 5.1 0 11.5 5.1 23 11.5 23zM9.2 5.2l6.3 6.3-.1.1-1.5 1.5-4.5 4.5L7.7 16l4.5-4.5-4.7-4.7 1.7-1.6z'%3E%3C/path%3E%3C/svg%3E"); }
        .qs-datepicker-container .qs-controls .qs-arrow.qs-right:after {
          content: unset !important; }
  .qs-datepicker-container .qs-month-year {
    font-size: 2rem !important;
    font-family: questa-sans, sans-serif !important;
    pointer-events: none; }
    @media (min-width: 1260px) {
      .qs-datepicker-container .qs-month-year {
        font-size: 2.2rem !important; } }
  .qs-datepicker-container .qs-square {
    position: relative;
    height: 39px !important;
    border-radius: 50% !important; }
    .qs-datepicker-container .qs-square:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      background: #F3F3F3;
      border: 2px solid #F3F3F3;
      border-radius: 50%;
      width: 24px !important;
      height: 24px !important;
      z-index: -1;
      opacity: 0;
      transition: opacity 0.2s; }
      @media (min-width: 1260px) {
        .qs-datepicker-container .qs-square:after {
          width: 32px !important;
          height: 32px !important; } }
    .qs-datepicker-container .qs-square:hover {
      background: transparent !important; }
      .qs-datepicker-container .qs-square:hover:after {
        opacity: 1; }
  .qs-datepicker-container .qs-event:after {
    right: auto !important;
    left: 50% !important;
    top: 50% !important;
    bottom: auto !important;
    transform: translateX(-50%) translateY(-50%) !important;
    background: none !important;
    border: 2px solid #CEDBAD;
    width: 24px !important;
    height: 24px !important;
    opacity: 1; }
    @media (min-width: 1260px) {
      .qs-datepicker-container .qs-event:after {
        width: 32px !important;
        height: 32px !important; } }
  .qs-datepicker-container .qs-event:hover:after {
    background: #F3F3F3 !important; }
  .qs-datepicker-container .qs-active {
    background: transparent !important; }
    .qs-datepicker-container .qs-active.qs-event:after {
      right: auto !important;
      left: 50% !important;
      top: 50% !important;
      bottom: auto !important;
      transform: translateX(-50%) translateY(-50%) !important;
      background: #CEDBAD !important;
      border: 2px solid #CEDBAD;
      width: 24px !important;
      height: 24px !important;
      z-index: -1;
      opacity: 1; }
      @media (min-width: 1260px) {
        .qs-datepicker-container .qs-active.qs-event:after {
          width: 32px !important;
          height: 32px !important; } }

.callout {
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: #F5DBA8;
  border-radius: 8px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
  text-decoration: none;
  font-weight: 500;
  margin-bottom: 40px;
  transition: all 0.2s; }
  @media (min-width: 768px) {
    .callout {
      justify-content: space-between;
      padding: 20px 40px; } }
  .callout .callout__icon {
    margin-left: 36px; }
  .callout--asset {
    background: #F1F0F6; }
    .callout--asset .callout__file {
      position: relative;
      display: inline-block;
      margin-right: 30px; }
      .callout--asset .callout__file .callout__file__extension {
        position: absolute;
        bottom: 3px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 1rem;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0; }
        .callout--asset .callout__file .callout__file__extension--small {
          font-size: 0.8rem; }
  .callout--stacked {
    display: block; }
    .callout--stacked .icon {
      margin-bottom: 20px; }
  @media (min-width: 992px) {
    .callout:hover {
      top: -10px;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2); } }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: box-shadow .3s; }
  .card .card__media {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    overflow: hidden; }
    .card .card__media img {
      display: block; }
  .card .pill {
    position: absolute;
    top: 0;
    right: 20px;
    transform: translateY(-50%); }
  .card .card__bar {
    position: relative;
    width: 42px;
    height: 3px;
    margin-bottom: 20px;
    background-color: #A7D5C2; }
    .card .card__bar--training {
      background-color: #E9B753; }
  .card .card__title a {
    text-decoration: none;
    color: inherit; }
    .card .card__title a:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5; }
  .card .card__content {
    background-color: #ffffff;
    padding: 0 20px 35px 20px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    flex: 1 1 auto; }
  .card .card__content__decor {
    display: block;
    position: relative;
    top: 1px;
    width: 100%;
    height: 1px; }
    .card .card__content__decor:before {
      content: '';
      position: absolute;
      top: 1px;
      left: 0;
      width: 100%;
      height: 44px;
      transform: translateY(-99%);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 370 53.7' preserveAspectRatio='none'%3E%3Cpath fill='%23ffffff' d='M0 .7c84.7 3.8 146.9 9.6 193.5 15.6S300.7 34.9 370 53.6H0V.7z'/%3E%3C/svg%3E");
      background-size: 100% 100%; }
  .card .card__read-more {
    text-decoration: underline;
    font-weight: 600;
    margin-bottom: 0; }
  .card:hover {
    box-shadow: 0 20px 30px 0 rgba(34, 51, 67, 0.2); }

@media (min-width: 992px) {
  .card--featured {
    display: flex;
    flex-direction: row;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px; }
    .card--featured .card__media {
      position: relative;
      width: 50%;
      border-radius: 8px; }
    .card--featured .card__content {
      position: unset;
      width: 50%;
      padding: 44px;
      border-bottom-left-radius: 0;
      border-top-right-radius: 8px; } }
    @media (min-width: 992px) and (min-width: 1260px) {
      .card--featured .card__content {
        padding: 60px; } }

@media (min-width: 992px) {
      .card--featured .card__content .pill {
        right: auto;
        left: 328px; } }
      @media (min-width: 992px) and (min-width: 1260px) {
        .card--featured .card__content .pill {
          left: 488px; } }

@media (min-width: 992px) {
    .card--featured .card__content__decor {
      display: none; } }

@media (min-width: 768px) {
  .card--featured {
    box-shadow: 0 20px 30px 0 rgba(34, 51, 67, 0.2); } }

.card-cta {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 10px 20px 0 rgba(34, 51, 67, 0.2);
  border-radius: 6px; }
  @media (min-width: 768px) {
    .card-cta {
      padding: 40px; } }
  @media (min-width: 1260px) {
    .card-cta {
      padding: 70px; } }
  .card-cta--dark {
    background-color: #A7D5C2; }
  .card-cta--light {
    background-color: #74A68E; }
  .card-cta--adult-services {
    background-color: #EA8A5D; }
  .card-cta--adult-services-light {
    background-color: #EEAA79; }
  .card-cta--child-services {
    background-color: #8A9366; }
  .card-cta--child-services-light {
    background-color: #CEDBAD; }
  .card-cta--yellow {
    background-color: #F5DBA8; }
  .card-cta--yellow-light {
    background-color: #FFF6E5; }
  .card-cta--sutherland-house-school {
    background-color: #74A68E; }
  .card-cta--sutherland-house-school-light {
    background-color: #A7D5C2; }
  @media (min-width: 1260px) {
    .card-cta--slim {
      padding: 40px 70px; } }
  .card-cta--height-full {
    height: 100%; }

.card-cta-w-image {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 20px 20px 0 20px;
  height: 100%;
  box-shadow: 0 10px 20px 0 rgba(34, 51, 67, 0.2);
  border-radius: 6px; }
  @media (min-width: 768px) {
    .card-cta-w-image {
      padding: 40px 40px 0 40px; } }
  @media (min-width: 1260px) {
    .card-cta-w-image {
      padding: 40px 70px 0 70px; } }
  .card-cta-w-image .card__content {
    margin-bottom: 32px; }
  .card-cta-w-image .card__media {
    position: relative;
    display: block;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    overflow: hidden;
    margin-left: -20px;
    margin-right: -20px; }
    @media (min-width: 768px) {
      .card-cta-w-image .card__media {
        margin-left: -40px;
        margin-right: -40px; } }
    @media (min-width: 1260px) {
      .card-cta-w-image .card__media {
        margin-left: -70px;
        margin-right: -70px; } }
    .card-cta-w-image .card__media img {
      display: block; }
    .card-cta-w-image .card__media:after {
      content: '';
      position: absolute;
      top: -1px;
      left: 0;
      width: 100%;
      height: 35px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 570 65' preserveAspectRatio='none'%3E%3Cpath fill='%23ffffff' d='M94.15.08C292.96.08 468 25.88 569.99 65V0H0v2.04C27.62.88 55.91.22 84.72.1l9.43-.02Z'/%3E%3C/svg%3E"); }
      @media (min-width: 768px) {
        .card-cta-w-image .card__media:after {
          height: 65px; } }
  .card-cta-w-image.card-cta-w-image--justgiving .card__media {
    height: 120px;
    background-color: #74A68E; }

.card-quote {
  position: relative;
  padding: 60px 40px;
  margin: 40px 0;
  background-color: #E4FFF4;
  border-left: 7px solid #74A68E; }
  .card-quote .quote__text p {
    font-size: 2rem; }
  .card-quote .icon > svg {
    width: 50px;
    height: 50px; }
  .card-quote .icon--top-left {
    position: absolute;
    top: -25px;
    left: 40px; }
  .card-quote .icon--bottom-right {
    position: absolute;
    bottom: -25px;
    right: 40px; }
  .card-quote--yellow {
    background-color: #F5DBA8;
    border-left: 7px solid #E9B753; }
  .card-quote--orange {
    background-color: #FFEEE2;
    border-left: 7px solid #EEAA79; }
  .card-quote--green {
    background-color: #F8FFED;
    border-left: 7px solid #CEDBAD; }
  .card-quote--pad {
    margin-bottom: 80px; }
  @media (min-width: 992px) {
    .card-quote--pad {
      margin-left: 100px;
      margin-right: 100px; } }

.card-page {
  position: relative;
  padding: 28px 26px;
  height: 100%;
  min-height: 230px;
  border-radius: 6px;
  box-shadow: none;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden; }
  .card-page .card__inner {
    position: relative;
    padding-right: 40px;
    z-index: 2; }
  .card-page .card__link {
    text-decoration: none;
    text-align: right;
    font-weight: bold;
    z-index: 2; }
    .card-page .card__link .icon .inner-circle,
    .card-page .card__link .icon .inner-arrow {
      transition: fill 0.2s; }
    .card-page .card__link:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .card-page:after {
    content: '';
    position: absolute;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 150px;
    background-repeat: no-repeat;
    background-position: bottom;
    z-index: 1;
    transition: all 0.3s;
    opacity: 0; }
  @media (min-width: 992px) {
    .card-page:hover {
      box-shadow: 0px 4px 24px 0px rgba(53, 47, 85, 0.3); }
      .card-page:hover .card__link .icon .inner-arrow {
        fill: #223343; }
      .card-page:hover:after {
        bottom: -1px;
        opacity: 1; } }
  .card-page--primary {
    background: #74A68E; }
    .card-page--primary:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 370 127.3' style='enable-background:new 0 0 370 127.3' xml:space='preserve'%3E%3Cpath d='M0 86.1v41.2h370V10C260.6 44.8 135.4 71 0 86.1z' style='fill-rule:evenodd;clip-rule:evenodd;fill:%23A7D5C2'/%3E%3C/svg%3E"); }
    @media (min-width: 992px) {
      .card-page--primary:hover .card__link .icon .inner-circle {
        fill: #74A68E; } }
    .card-page--primary.card-page--shs {
      background: #A7D5C2; }
      .card-page--primary.card-page--shs:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 370 127.3' style='enable-background:new 0 0 370 127.3' xml:space='preserve'%3E%3Cpath d='M0 86.1v41.2h370V10C260.6 44.8 135.4 71 0 86.1z' style='fill-rule:evenodd;clip-rule:evenodd;fill:%2374A68E'/%3E%3C/svg%3E"); }
      @media (min-width: 992px) {
        .card-page--primary.card-page--shs:hover .card__link .icon .inner-circle {
          fill: #A7D5C2; } }
    .card-page--primary.card-page--shs-white {
      box-shadow: 0 20px 30px 0 rgba(34, 51, 67, 0.2);
      background: #ffffff; }
      .card-page--primary.card-page--shs-white:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 370 127.3' style='enable-background:new 0 0 370 127.3' xml:space='preserve'%3E%3Cpath d='M0 86.1v41.2h370V10C260.6 44.8 135.4 71 0 86.1z' style='fill-rule:evenodd;clip-rule:evenodd;fill:%23A7D5C2'/%3E%3C/svg%3E"); }
      @media (min-width: 992px) {
        .card-page--primary.card-page--shs-white:hover {
          box-shadow: 0 20px 30px 0 rgba(34, 51, 67, 0.3); } }
  .card-page--orange {
    background: #EEAA79; }
    .card-page--orange:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 370 127.3' style='enable-background:new 0 0 370 127.3' xml:space='preserve'%3E%3Cpath d='M0 86.1v41.2h370V10C260.6 44.8 135.4 71 0 86.1z' style='fill-rule:evenodd;clip-rule:evenodd;fill:%23EA8A5D'/%3E%3C/svg%3E"); }
    @media (min-width: 992px) {
      .card-page--orange:hover .card__link .icon .inner-circle {
        fill: #EEAA79; } }
  .card-page--green {
    background: #CEDBAD; }
    .card-page--green:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 370 127.3' style='enable-background:new 0 0 370 127.3' xml:space='preserve'%3E%3Cpath d='M0 86.1v41.2h370V10C260.6 44.8 135.4 71 0 86.1z' style='fill-rule:evenodd;clip-rule:evenodd;fill:%238A9366'/%3E%3C/svg%3E"); }
    @media (min-width: 992px) {
      .card-page--green:hover .card__link .icon .inner-circle {
        fill: #CEDBAD; } }
  .card-page--extended .card__inner {
    position: unset; }
  .card-page--extended .link-arrow:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  @media (min-width: 992px) {
    .card-page--extended {
      padding-bottom: 60px; }
      .card-page--extended:hover:after {
        bottom: -5%;
        opacity: 1; } }

.card-popout {
  position: relative;
  transform: translateY(0px);
  display: block;
  text-decoration: none;
  transition: all 0.3s;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.25)); }
  .card-popout:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 60%;
    background-repeat: no-repeat;
    background-position: bottom;
    z-index: 1;
    transition: all 0.3s;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px; }
  .card-popout .card__inner {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 14px 20px;
    z-index: 2; }
    @media (min-width: 992px) {
      .card-popout .card__inner {
        padding: 16px 26px; } }
    @media (min-width: 1260px) {
      .card-popout .card__inner {
        padding: 22px 36px; } }
    .card-popout .card__inner .card__title {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-decoration: none; }
      .card-popout .card__inner .card__title h2 {
        margin-right: 32px;
        font-size: 2.2rem;
        margin-bottom: 0; }
        @media (min-width: 992px) {
          .card-popout .card__inner .card__title h2 {
            font-size: 3rem; } }
      .card-popout .card__inner .card__title .icon {
        transition: fill 0.3s; }
        .card-popout .card__inner .card__title .icon .inner-arrow {
          fill: #223343; }
        .card-popout .card__inner .card__title .icon .inner-circle {
          transition: fill 0.3s; }
  .card-popout .card__media {
    width: 100%;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    overflow: hidden; }
    .card-popout .card__media img {
      display: block; }
  @media (min-width: 992px) {
    .card-popout:hover {
      transform: translateY(-10px);
      filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.35)); }
    .safari .card-popout:hover {
      transform: translateY(0px);
      filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.25)); } }
  .card-popout--school {
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0)); }
    .card-popout--school:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 589.5 256' preserveAspectRatio='none'%3E%3Cpath fill='%23A7D5C2' d='M589.5 6.6C542.3 83 388.7 139.1 206.6 139.1c-75.7 0-146.4-9.7-206.6-26.5V256h589.5V6.6z'/%3E%3C/svg%3E") !important; }
    .card-popout--school .card__media {
      position: relative; }
      .card-popout--school .card__media:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 80%;
        background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0) 100%); }
      .card-popout--school .card__media svg {
        height: 31px;
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 2; }
    @media (min-width: 1260px) {
      .card-popout--school .card__inner {
        padding: 22px 26px; } }
    .card-popout--school .card__inner .card__title .icon .inner-circle {
      fill: #74A68E !important; }
    .card-popout--school .card__inner .card__title h2 {
      font-size: 2.2rem;
      margin-right: 16px; }
      @media (min-width: 992px) {
        .card-popout--school .card__inner .card__title h2 {
          font-size: 2.2rem; } }
  .card-popout--orange:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 589.5 256' preserveAspectRatio='none'%3E%3Cpath fill='%23EA8A5D' d='M589.5 6.6C542.3 83 388.7 139.1 206.6 139.1c-75.7 0-146.4-9.7-206.6-26.5V256h589.5V6.6z'/%3E%3C/svg%3E"); }
  .card-popout--orange .card__inner .card__title .icon .inner-circle {
    fill: #EEAA79; }
  @media (min-width: 992px) {
    .card-popout--orange:hover .card__title .icon .inner-circle {
      fill: #f4c7a6; } }
  .card-popout--green:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 589.5 256' preserveAspectRatio='none'%3E%3Cpath fill='%238A9366' d='M589.5 6.6C542.3 83 388.7 139.1 206.6 139.1c-75.7 0-146.4-9.7-206.6-26.5V256h589.5V6.6z'/%3E%3C/svg%3E"); }
  .card-popout--green .card__inner .card__title .icon .inner-circle {
    fill: #CEDBAD; }
  @media (min-width: 992px) {
    .card-popout--green:hover .card__title .icon .inner-circle {
      fill: #e3ebd0; } }
  .card-popout--primary-main:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 589.5 256' preserveAspectRatio='none'%3E%3Cpath fill='%23A7D5C2' d='M589.5 6.6C542.3 83 388.7 139.1 206.6 139.1c-75.7 0-146.4-9.7-206.6-26.5V256h589.5V6.6z'/%3E%3C/svg%3E"); }
  .card-popout--primary-main .card__inner .card__title .icon .inner-circle {
    fill: #74A68E; }
  @media (min-width: 992px) {
    .card-popout--primary-main:hover .card__title .icon .inner-circle {
      fill: #93baa7; } }
  .card-popout--primary-secondary:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 589.5 256' preserveAspectRatio='none'%3E%3Cpath fill='%2374A68E' d='M589.5 6.6C542.3 83 388.7 139.1 206.6 139.1c-75.7 0-146.4-9.7-206.6-26.5V256h589.5V6.6z'/%3E%3C/svg%3E"); }
  .card-popout--primary-secondary .card__inner .card__title .icon .inner-circle {
    fill: #A7D5C2; }
  @media (min-width: 992px) {
    .card-popout--primary-secondary:hover .card__title .icon .inner-circle {
      fill: #cae5da; } }

.card-social {
  position: relative;
  padding: 20px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.04);
  font-size: 1.4rem; }
  .card-social .card__profile {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-right: 40px;
    margin-bottom: 25px; }
    .card-social .card__profile p {
      margin-bottom: 0;
      line-height: 1.45; }
    .card-social .card__profile .card__profile__image {
      margin-right: 15px;
      width: 40px;
      height: 40px;
      flex: 0 0 auto;
      border-radius: 50%; }
  .card-social .card__link {
    position: absolute;
    top: 20px;
    right: 20px; }
    .card-social .card__link .icon > svg path {
      transition: fill 0.2s; }
    .card-social .card__link.twitter:hover .icon > svg path {
      fill: #1DA1F2 !important; }
    .card-social .card__link.fb:hover .icon > svg path {
      fill: #3b5998 !important; }

.card-team {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  height: 100%;
  background-color: #e4c1b8; }
  .card-team .img__hold {
    position: relative; }
  .card-team .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("/dist/img/bkg/card-team.svg");
    background-size: 104%;
    background-repeat: no-repeat;
    background-position: bottom -2px center; }
    @media (min-width: 992px) {
      .card-team .overlay {
        background-size: 120%; } }
    @media (min-width: 1260px) {
      .card-team .overlay {
        background-size: 104%; } }
  .card-team .text {
    padding: 20px;
    position: relative;
    margin-top: -70px; }
    .card-team .text p {
      margin-bottom: 0;
      line-height: 1.4; }
  .card-team--school {
    background-color: #A7D5C2; }
    .card-team--school .overlay {
      background-image: url("/dist/img/bkg/card-team-school.svg"); }

.card-job {
  text-decoration: none;
  display: block;
  position: relative;
  background: #ffffff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
  padding: 20px;
  margin-bottom: 20px;
  transition: transform .3s; }
  @media (min-width: 768px) {
    .card-job {
      padding: 30px 240px 30px 30px; }
      .card-job p {
        margin: 0; }
      .card-job .btn {
        position: absolute;
        bottom: 30px;
        right: 30px; }
      .card-job:hover {
        transform: translateY(-10px); }
        .card-job:hover .btn {
          background-color: #60927b;
          color: #fff; } }
  @media (min-width: 992px) {
    .card-job {
      padding: 45px 300px 45px 50px; }
      .card-job .btn {
        bottom: 50px;
        right: 50px; } }

.contact-block {
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0 -4px 20px 0 rgba(34, 51, 67, 0.2);
  overflow: hidden; }

.contact-block__information {
  position: relative;
  padding: 32px;
  height: 100%;
  background: #E4FFF4;
  overflow: hidden; }
  @media (min-width: 992px) {
    .contact-block__information {
      padding: 54px; } }
  .contact-block__information:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 220px;
    pointer-events: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 420 345' preserveAspectRatio='none'%3E%3Cpath fill='%2374A68E' d='M420 0c-43.6 55-110.6 96.1-201.1 123.5-47.5 14.3-121.2 25.1-218.9 32V345h420V0z'/%3E%3C/svg%3E"); }
    .contact-block--shs .contact-block__information:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 420 345' preserveAspectRatio='none'%3E%3Cpath fill='%23A7D5C2' d='M420 0c-43.6 55-110.6 96.1-201.1 123.5-47.5 14.3-121.2 25.1-218.9 32V345h420V0z'/%3E%3C/svg%3E"); }
    @media (min-width: 992px) {
      .contact-block__information:after {
        height: 300px; } }
    @media (min-width: 1260px) {
      .contact-block__information:after {
        height: 350px; } }

.contact-block__information__inner {
  position: relative;
  z-index: 1;
  padding-bottom: 200px; }
  @media (min-width: 992px) {
    .contact-block__information__inner {
      padding-bottom: 300px; } }
  @media (min-width: 1260px) {
    .contact-block__information__inner {
      padding-bottom: 350px; } }

.contact-block__form {
  padding: 32px; }
  @media (min-width: 992px) {
    .contact-block__form {
      padding: 54px; } }

.contact-block__service-selector__item {
  display: block;
  border-radius: 8px;
  padding: 12px 30px;
  text-decoration: none;
  text-align: center;
  line-height: 1.2;
  font-weight: 300;
  color: #223343;
  background: #E4FFF4; }
  .contact-block__service-selector__item--selected {
    background: #A7D5C2;
    color: #ffffff; }

#contact-block-scroll-trigger {
  position: relative;
  top: -200px; }

#cookie-message {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  padding: 25px; }
  #cookie-message .inner {
    background-color: #A7D5C2;
    padding: 25px;
    box-shadow: 0 10px 20px 0 rgba(31, 30, 65, 0.24);
    border-radius: 10px; }
  #cookie-message .wrapper {
    padding: 2.66667rem 1.33334rem; }
  #cookie-message .btn {
    margin-bottom: 15px; }
  #cookie-message .btn:first-of-type {
    margin-right: 15px; }
  #cookie-message .g .h3 {
    margin-bottom: 0; }

@media (min-width: 768px) {
  #cookie-message .inner {
    padding: 45px 45px 25px; }
  #cookie-message .g {
    margin-bottom: 25px; }
    #cookie-message .g p {
      margin-bottom: 0; }
  #cookie-message .button__hold {
    margin-top: 30px; } }

@media (min-width: 992px) {
  #cookie-message {
    width: 590px; } }

@media (min-width: 1260px) {
  #cookie-message {
    width: 630px; } }

.cta-feature {
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    .cta-feature {
      flex-direction: row; } }
  .cta-feature--orange .cta-feature__content:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 327.8 82.9'%3E%3Cpath d='M327.8 82.9C210.5 65.3 101.2 37.7 0 0v82.9h327.8z' fill='%23EA8A5D'/%3E%3C/svg%3E"); }
  .cta-feature--green .cta-feature__content:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 327.8 82.9'%3E%3Cpath d='M327.8 82.9C210.5 65.3 101.2 37.7 0 0v82.9h327.8z' fill='%238A9366'/%3E%3C/svg%3E"); }
  @media (min-width: 992px) {
    .cta-feature--vertical-center {
      align-items: center; }
      .cta-feature--vertical-center .cta-feature__content {
        margin-top: 0; } }
  @media (min-width: 768px) {
    .cta-feature--flip {
      flex-direction: row-reverse; }
      .cta-feature--flip .cta-feature__content {
        margin-left: -25px; }
      .cta-feature--flip .cta-feature__media {
        margin-right: -25px; } }

.cta-feature__content {
  position: relative;
  background: #ffffff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 26px 26px 34px 26px; }
  @media (min-width: 768px) {
    .cta-feature__content {
      padding: 38px 38px 60px 38px;
      box-shadow: 20px 20px 30px 0px rgba(0, 0, 0, 0.1);
      margin-right: -25px;
      margin-top: 50px;
      width: calc(55% + 25px);
      border-radius: 6px; } }
  @media (min-width: 992px) {
    .cta-feature__content {
      padding: 50px 50px 80px 50px; } }
  .cta-feature__content:before {
    content: '';
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 195px;
    height: 50px;
    background-size: 100% 100%;
    background-position: left bottom;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 327.8 82.9'%3E%3Cpath d='M327.8 82.9C210.5 65.3 101.2 37.7 0 0v82.9h327.8z' fill='%23A7D5C2'/%3E%3C/svg%3E");
    border-bottom-left-radius: 6px; }
    @media (min-width: 768px) {
      .cta-feature__content:before {
        display: block; } }

@media (max-width: 767px) {
  .cta-feature__media img.img--rounded {
    border-top-left-radius: 0;
    border-top-right-radius: 0; } }

@media (min-width: 768px) {
  .cta-feature__media {
    margin-left: -25px;
    width: calc(45% + 25px); } }

.cta-feature__sparkle {
  position: absolute;
  display: none; }
  .cta-feature__sparkle > svg {
    width: 100%; }
  @media (min-width: 992px) {
    .cta-feature__sparkle {
      display: block; } }
  .cta-feature__sparkle--1 {
    top: -18px;
    right: 20px;
    width: 60px; }
    @media (min-width: 1260px) {
      .cta-feature__sparkle--1 {
        width: 80px; } }
  .cta-feature__sparkle--2 {
    bottom: 20px;
    right: 20px;
    width: 100px; }
    @media (min-width: 1260px) {
      .cta-feature__sparkle--2 {
        width: 124px; } }

.cta-sutherland {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  overflow: hidden; }
  @media (min-width: 768px) {
    .cta-sutherland {
      flex-direction: row; } }
  .cta-sutherland:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 101%;
    height: 30%;
    background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1169.5 264.5'%3E%3Cpath fill='%23A7D5C2' d='M684.2 264.6c1.1-.1 2.2-.2 3.3-.2h482V.6c-178.8 144-548.7 242.8-976.2 242.8-66.1 0-130.5-2.4-193.4-6.9v27.9h680.9c1.2.1 2.3.1 3.4.2z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: 1;
    pointer-events: none; }
    @media (min-width: 480px) {
      .cta-sutherland:after {
        height: 40%; } }
    @media (min-width: 768px) {
      .cta-sutherland:after {
        height: 65%; } }
  .cta-sutherland--orange .cta-sutherland__content {
    background: #FFEEE2; }
  .cta-sutherland--orange:after {
    background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1169.5 264.5'%3E%3Cpath fill='%23EA8A5D' d='M684.2 264.6c1.1-.1 2.2-.2 3.3-.2h482V.6c-178.8 144-548.7 242.8-976.2 242.8-66.1 0-130.5-2.4-193.4-6.9v27.9h680.9c1.2.1 2.3.1 3.4.2z'/%3E%3C/svg%3E"); }
  .cta-sutherland--green .cta-sutherland__content {
    background: #CEDBAD; }
  .cta-sutherland--green:after {
    background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1169.5 264.5'%3E%3Cpath fill='%238A9366' d='M684.2 264.6c1.1-.1 2.2-.2 3.3-.2h482V.6c-178.8 144-548.7 242.8-976.2 242.8-66.1 0-130.5-2.4-193.4-6.9v27.9h680.9c1.2.1 2.3.1 3.4.2z'/%3E%3C/svg%3E"); }

.cta-sutherland__content {
  background: #E4FFF4;
  padding: 26px 26px 34px 26px; }
  @media (min-width: 768px) {
    .cta-sutherland__content {
      width: 50%;
      padding: 38px 38px 60px 38px; } }
  @media (min-width: 992px) {
    .cta-sutherland__content {
      padding: 50px 50px 80px 50px; } }

.cta-sutherland__media {
  position: relative; }
  .cta-sutherland__media picture {
    display: block;
    height: 100%; }
  .cta-sutherland__media img {
    display: block;
    object-fit: cover;
    height: 100%; }
  @media (max-width: 767px) {
    .cta-sutherland__media img {
      border-top-left-radius: 0;
      border-top-right-radius: 0; } }
  @media (min-width: 768px) {
    .cta-sutherland__media {
      width: 50%; }
      .cta-sutherland__media img {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%; } }

.cta-singular {
  position: relative;
  padding: 30px 26px 50px 26px;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0 20px 20px 0px rgba(34, 51, 67, 0.1); }
  .cta-singular .cta-singular__inner {
    max-width: 720px;
    margin: 0 auto; }
  @media (min-width: 768px) {
    .cta-singular {
      padding: 50px; } }
  @media (min-width: 992px) {
    .cta-singular {
      padding: 70px;
      margin-left: 8px;
      margin-right: 8px; } }

.cta-singular__sparkle {
  position: absolute;
  display: none; }
  .cta-singular__sparkle > svg {
    width: 100%; }
  @media (min-width: 768px) {
    .cta-singular__sparkle {
      display: block; } }
  .cta-singular__sparkle--1 {
    top: -8px;
    right: -8px;
    width: 60px; }
    @media (min-width: 1260px) {
      .cta-singular__sparkle--1 {
        width: 80px; } }
  .cta-singular__sparkle--2 {
    top: -8px;
    left: -8px;
    width: 60px; }
    @media (min-width: 1260px) {
      .cta-singular__sparkle--2 {
        width: 80px; } }
  .cta-singular__sparkle--3 {
    bottom: 0;
    left: -8px;
    width: 100px; }
    @media (min-width: 1260px) {
      .cta-singular__sparkle--3 {
        width: 124px; } }
  .cta-singular__sparkle--4 {
    bottom: 0;
    right: -8px;
    width: 100px; }
    @media (min-width: 1260px) {
      .cta-singular__sparkle--4 {
        width: 124px; } }

.cta-singular--w-image-cover {
  padding: 0; }
  @media (min-width: 768px) {
    .cta-singular--w-image-cover .cta-singular__wrapper {
      display: flex; } }
  .cta-singular--w-image-cover .cta-singular__wrapper .cta-singular__media {
    position: relative;
    width: 100%; }
    @media (max-width: 767px) {
      .cta-singular--w-image-cover .cta-singular__wrapper .cta-singular__media {
        height: 280px; } }
    @media (min-width: 768px) {
      .cta-singular--w-image-cover .cta-singular__wrapper .cta-singular__media {
        width: 40%;
        flex-shrink: 0; } }
    .cta-singular--w-image-cover .cta-singular__wrapper .cta-singular__media img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px; }
      @media (min-width: 768px) {
        .cta-singular--w-image-cover .cta-singular__wrapper .cta-singular__media img {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px; } }
    .cta-singular--w-image-cover .cta-singular__wrapper .cta-singular__media .curve {
      display: none; }
    @media (min-width: 768px) {
      .cta-singular--w-image-cover .cta-singular__wrapper .cta-singular__media .curve {
        position: absolute;
        top: 0;
        right: -1px;
        height: 100%;
        width: auto;
        display: block; } }
  .cta-singular--w-image-cover .cta-singular__wrapper .cta-singular__inner {
    padding: 30px 26px 50px 26px; }
    @media (min-width: 768px) {
      .cta-singular--w-image-cover .cta-singular__wrapper .cta-singular__inner {
        padding: 50px; } }
    @media (min-width: 992px) {
      .cta-singular--w-image-cover .cta-singular__wrapper .cta-singular__inner {
        padding: 70px; } }

.cta-search {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 32px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 10px 20px 0px rgba(34, 51, 67, 0.1); }
  .cta-search p {
    margin-bottom: 20px; }
  .cta-search .btn:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  @media (min-width: 768px) {
    .cta-search {
      flex-direction: row; }
      .cta-search p {
        margin-bottom: 0; } }
  @media (min-width: 992px) {
    .cta-search {
      padding: 52px 72px; } }

.form button[type=submit] {
  opacity: 1;
  transition: all 0.3s; }

.form.is-hidden {
  display: none; }

.input__hold {
  position: relative;
  margin-bottom: 32px; }
  .input__hold.has--file input[type=file] {
    color: #D1DDE6; }

input {
  font-family: canada-type-gibson;
  width: 100%;
  border: none;
  border-radius: 0;
  border: 2px solid #D1DDE6;
  border-radius: 8px;
  background: #ffffff;
  font-size: 16px;
  padding: 14px;
  outline: none;
  -webkit-appearance: none;
  /* for box shadows to show on iOS */
  transition: background .3s, border .5s; }
  @media (min-width: 768px) {
    input {
      font-size: 1.6rem; } }
  input:focus {
    border-color: #A7D5C2;
    background-color: #f2f2f2; }
  input::placeholder {
    color: rgba(34, 51, 67, 0.7); }
  input[disabled] {
    background-color: #f7f7f8;
    cursor: not-allowed; }

input[type=checkbox] {
  -webkit-appearance: checkbox;
  margin: 0; }

input[type=file] {
  position: relative;
  cursor: pointer;
  padding: 14px;
  color: #223343;
  text-indent: -90px; }
  input[type=file]::placeholder {
    color: #223343;
    opacity: 1; }
  input[type=file]::-webkit-file-upload-button {
    visibility: hidden; }
  input[type=file]:after {
    content: 'Choose file';
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
    font-size: 1.6rem;
    background: #E4FFF4;
    color: #223343;
    padding: 14px 22px;
    text-indent: initial;
    border-radius: 8px; }

input[type=radio]:checked, input[type=radio]:not(:checked) {
  position: absolute;
  left: -9999px; }

input[type=radio]:checked + label, input[type=radio]:not(:checked) + label {
  position: relative;
  padding-right: 35px;
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  line-height: 1.2; }

input[type=radio]:checked + label:before, input[type=radio]:not(:checked) + label:before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #D1DDE6;
  border-radius: 100%;
  background: transparent; }

input[type=radio]:checked + label:after, input[type=radio]:not(:checked) + label:after {
  content: '';
  position: absolute;
  top: 5px;
  right: 5px;
  width: 14px;
  height: 14px;
  background: #223343;
  border-radius: 100%;
  transition: all 0.2s ease; }

input[type=radio]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0); }

input[type=radio]:checked + label:after {
  opacity: 1;
  transform: scale(1); }

/* Radio buttons
//------------------*/
.radio-buttons fieldset > div > div {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px 30px; }
  .radio-buttons fieldset > div > div .radio {
    grid-column: span 4 / span 4; }

.radio-buttons .radio label {
  display: block;
  width: 100%;
  margin-bottom: 0;
  border-radius: 8px;
  padding: 12px 30px;
  text-decoration: none;
  text-align: center;
  line-height: 1.2;
  font-weight: 300 !important;
  color: #223343;
  background: #E4FFF4; }
  .radio-buttons .radio label:after, .radio-buttons .radio label:before {
    content: '';
    display: none; }
  @media (min-width: 992px) {
    .radio-buttons .radio label:hover, .radio-buttons .radio label:focus {
      background: #A7D5C2;
      color: #ffffff; } }

.radio-buttons .radio input[type="radio"] {
  appearance: none; }
  .radio-buttons .radio input[type="radio"]:checked + label {
    background: #A7D5C2;
    color: #ffffff; }

textarea {
  font-family: canada-type-gibson;
  width: 100%;
  border: none;
  border: 2px solid #D1DDE6;
  border-radius: 8px;
  background: #ffffff;
  font-size: 1.6rem;
  line-height: 1.5;
  padding: 14px;
  outline: none;
  transition: background .3s, border .5s; }
  textarea:focus {
    border-color: #A7D5C2;
    background-color: #f2f2f2; }
  textarea:not(:empty) {
    background-color: #fbfbfb; }

select {
  font-family: canada-type-gibson;
  color: rgba(34, 51, 67, 0.7);
  width: 100%;
  border: solid 2px #D1DDE6;
  border-radius: 8px;
  line-height: 1.2;
  padding: 14px 34px 14px 14px;
  outline: none;
  -webkit-appearance: none;
  /* for box shadows to show on iOS */
  background-color: #ffffff;
  background-image: url("../img/icons/select-arrow-down-black.svg");
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: right 14px top 50%;
  font-size: 1.6rem;
  transition: border-bottom 0.2s; }
  select.required {
    border-bottom: solid 2px #EE3D52; }

label {
  display: block;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 12px;
  transition: all 0.2s; }

legend {
  display: block;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 12px;
  transition: all 0.2s; }

.checkbox {
  display: flex; }
  .checkbox label {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    line-height: 1.6;
    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
    padding-left: 30px; }
    .checkbox label::before, .checkbox label::after {
      position: absolute;
      content: "";
      /*Needed for the line-height to take effect*/
      display: inline-block; }
    .checkbox label::before {
      height: 16px;
      width: 16px;
      border: solid 2px #D1DDE6;
      border-radius: 4px;
      left: 1px;
      background-color: transparent;
      /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
       *to vertically center it.
       */
      top: 4px; }
    .checkbox label::after {
      color: #ffffff;
      height: 5px;
      width: 9px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      left: 5px;
      top: 9px; }
  .checkbox input[type="checkbox"] {
    display: none; }
    .checkbox input[type="checkbox"] + label::after {
      content: none; }
    .checkbox input[type="checkbox"]:checked + label:before {
      background-color: #333333;
      border-color: #333333; }
    .checkbox input[type="checkbox"]:checked + label::after {
      content: ""; }
    .checkbox input[type="checkbox"]:focus + label::before {
      outline: #3b99fc auto 5px; }
    .checkbox input[type="checkbox"]:disabled ~ label:before, .checkbox input[type="checkbox"]:disabled ~ label:after {
      opacity: .5; }
  .checkbox.error label::before, .checkbox.error label::after {
    animation: shake .5s ease-in-out forwards; }

.checkbox__group .checkbox {
  display: inline-flex; }

.checkbox__group ul {
  margin: 0;
  padding: 0; }
  .checkbox__group ul li {
    width: 49.5%;
    padding-right: 25px; }
    .checkbox__group ul li:last-of-type {
      margin-right: 0; }

.checkbox__group input {
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
  position: absolute !important;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden; }

#website {
  display: none; }

.input--success {
  border-color: #32b292; }

.input--error {
  border-color: #EE3D52; }

.form-container {
  transition: all .3s; }

.form__success {
  display: none; }
  .form__success.is-visible {
    display: block; }

.form--submitting button[type=submit] {
  animation: submitPulse 2s infinite;
  animation-delay: 0.3s;
  opacity: 0.25;
  pointer-events: none; }

@keyframes submitPulse {
  0% {
    opacity: 0.25; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 0.25; } }

.row-mb {
  margin-bottom: 32px !important; }

.formie-recaptcha-placeholder {
  margin-bottom: 32px; }

.fui-alert {
  line-height: 1.6 !important;
  padding-left: 0 !important;
  padding-right: 0 !important; }

.navigation-burger {
  position: relative;
  z-index: 10;
  display: inline-block;
  background-color: white;
  border-radius: 50%;
  padding: 9px 9px;
  width: 40px;
  height: 40px; }

.hamburger {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  height: 15px;
  margin: 0;
  position: relative;
  width: 22px;
  outline: none; }
  .hamburger span {
    height: 0;
    border-bottom: 2px solid #A7D5C2;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    transition: all 200ms ease-out;
    backface-visibility: hidden;
    border-radius: 2px; }
  .hamburger:hover span {
    width: 100%; }
  .hamburger:after, .hamburger:before {
    transform: translateY(-50%);
    position: absolute;
    border-bottom: 2px solid #A7D5C2;
    border-radius: 2px;
    content: '';
    height: 0;
    width: 100%;
    right: 0;
    transition: all .3s ease-out; }
  .hamburger:before {
    top: 0; }
  .hamburger:after {
    top: 100%; }
  .hamburger.open:before {
    top: 50%; }
  .hamburger.open:after {
    top: 50%; }

@media (min-width: 992px) {
  .navigation-burger {
    display: none; } }

.hero {
  position: relative;
  padding-top: 35px;
  background-color: #A7D5C2; }
  .hero .breadcrumb {
    margin-bottom: 3.5vh; }
  .hero .curve {
    position: relative;
    top: 2px;
    left: 50%;
    transform: translateX(-50%);
    width: 101%;
    padding-top: 20px;
    font-size: 0;
    line-height: 0; }
  @media (min-width: 1260px) {
    .hero .inner .gs__1-2:first-child {
      padding-right: 40px; } }

.hero__intro-image {
  border-radius: 8px;
  overflow: hidden;
  margin-top: -200px; }
  .hero__intro-image img {
    display: block; }
  @media (min-width: 1260px) {
    .hero__intro-image {
      margin-top: -300px; } }

.hero--light {
  background-color: #E4FFF4; }

.hero--curve-absolute .curve {
  position: absolute;
  top: auto;
  bottom: -2px;
  left: 50%;
  width: 101%;
  transform: translateX(-50%); }

.hero--home {
  padding-top: 0;
  background-color: transparent; }
  .hero--home .inner {
    padding: 50px 0 115vw 0; }
    @media (min-width: 576px) {
      .hero--home .inner {
        padding: 50px 0 100px 0; } }
    @media (min-width: 768px) {
      .hero--home .inner {
        padding: 250px 0 160px 0; } }
  .hero--home .hero__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; }
    .hero--home .hero__image img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translateX(-50%) translateY(-50%);
      object-fit: cover; }
    .hero--home .hero__image--desktop {
      display: none; }
      .hero--home .hero__image--desktop img {
        filter: brightness(50%) contrast(110%); }
      .hero--home .hero__image--desktop:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(180deg, #FFFFFF 0%, rgba(172, 172, 172, 0.1) 25%); }
      @media (min-width: 576px) {
        .hero--home .hero__image--desktop {
          display: block; } }
    .hero--home .hero__image--mobile img {
      top: auto;
      bottom: 0;
      object-position: bottom;
      transform: translateX(-50%);
      filter: brightness(50%) contrast(110%); }
    .hero--home .hero__image--mobile:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(172, 172, 172, 0.1) 35%); }
    @media (min-width: 576px) {
      .hero--home .hero__image--mobile {
        display: none; } }
  .hero--home.hero--home-shs .hero__image:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.1) 25%); }
  .hero--home.hero--home-shs .hero__image img {
    filter: brightness(115%); }

.hero--training {
  background-color: #E9B753; }
  .hero--training .inner {
    margin-bottom: 40px; }

.hero--training-light {
  background-color: #FFF6E5; }

.hero--training .curve, .hero--training-light .curve {
  margin-top: -40px; }

.hero--as {
  background-color: #EA8A5D; }
  .hero--as .inner {
    margin-bottom: 40px; }

.hero--as-light {
  background-color: #FFEEE2; }

.hero--as .curve, .hero--as-light .curve {
  margin-top: -40px; }

.hero--cs {
  background-color: #8A9366; }
  .hero--cs .inner {
    margin-bottom: 40px; }

.hero--cs-light {
  background-color: #F8FFED; }

.hero--cs .curve, .hero--cs-light .curve {
  margin-top: -40px; }

.hero--shs {
  background-color: #74A68E; }
  .hero--shs .inner {
    margin-bottom: 40px; }

.hero--intro-image .curve {
  padding-top: 200px; }

@media (min-width: 1260px) {
  .hero--intro-image .curve {
    padding-top: 250px; } }

.icon {
  display: inline-block; }
  .icon > svg {
    display: block; }
  .icon path, .icon polygon {
    transition: fill .3s; }

.icon--white > svg {
  fill: #ffffff !important; }

.icon--black > svg {
  fill: #333333 !important; }

.icon--primary > svg {
  fill: #A7D5C2 !important; }

.icon--primary-secondary > svg {
  fill: #74A68E !important; }

.icon--white > svg {
  fill: #ffffff; }

.icon--black > svg {
  fill: #333333; }

.icon--black-font > svg {
  fill: #223343; }

.icon--lighter > svg {
  fill: #F3F3F3; }

.icon--light > svg {
  fill: #eaeaea; }

.icon--grey > svg {
  fill: #D1DDE6; }

.icon--dark > svg {
  fill: #3a3a3a; }

.icon--orange-lighter > svg {
  fill: #FFEEE2; }

.icon--orange-light > svg {
  fill: #EEAA79; }

.icon--orange > svg {
  fill: #EA8A5D; }

.icon--yellow-lighter > svg {
  fill: #FFF6E5; }

.icon--yellow-light > svg {
  fill: #F5DBA8; }

.icon--yellow > svg {
  fill: #E9B753; }

.icon--green-lighter > svg {
  fill: #F8FFED; }

.icon--green-light > svg {
  fill: #CEDBAD; }

.icon--green > svg {
  fill: #8A9366; }

.icon--green-success > svg {
  fill: #32b292; }

.icon--green-brand > svg {
  fill: #74A68E; }

.icon--sutherland-light > svg {
  fill: #A7D5C2; }

.icon--sutherland > svg {
  fill: #74A68E; }

.icon--red-error > svg {
  fill: #EE3D52; }

.icon--15 > svg {
  width: 15px;
  height: 15px; }

.icon--20 > svg {
  width: 20px;
  height: 20px; }

.icon--25 > svg {
  width: 25px;
  height: 25px; }

.icon--30 > svg {
  width: 30px;
  height: 30px; }

.icon--40 > svg {
  width: 40px;
  height: 40px; }

.icon--60 > svg {
  width: 60px;
  height: 60px; }

@media (max-width: 991px) {
  .icon--30-medium-down > svg {
    width: 30px;
    height: 30px; } }

.icon-card {
  position: relative;
  height: 100%;
  padding: 32px;
  background: #A7D5C2;
  border-radius: 6px; }
  @media (min-width: 768px) {
    .icon-card {
      padding: 38px 72px; } }
  .icon-card h2 {
    padding-right: 90px; }
    @media (min-width: 768px) {
      .icon-card h2 {
        padding-right: 100px; } }
  .icon-card p {
    line-height: 2; }
  .icon-card--odd {
    background: #A7D5C2; }
    .icon-card--odd h2 {
      color: #ffffff; }
    .icon-card--odd p, .icon-card--odd p a, .icon-card--odd li {
      color: #ffffff;
      font-size: 18px; }
  .icon-card--even {
    background: #74A68E; }
  .icon-card .icon-card__inner {
    position: relative;
    z-index: 2; }
  .icon-card .icon-card__icon {
    position: absolute;
    top: -16px;
    right: 16px;
    width: 80px;
    z-index: 1; }
    @media (min-width: 768px) {
      .icon-card .icon-card__icon {
        width: 88px;
        right: 24px; } }
    @media (min-width: 992px) {
      .icon-card .icon-card__icon {
        width: 110px;
        right: 32px; } }
    .icon-card .icon-card__icon img {
      position: relative;
      z-index: 3; }
    .icon-card .icon-card__icon .icon-card__sparkle {
      position: absolute;
      top: 50px;
      right: 20px;
      width: 80px;
      z-index: 2; }
      @media (min-width: 768px) {
        .icon-card .icon-card__icon .icon-card__sparkle {
          top: 50px;
          right: 12px;
          width: 100px; } }
      @media (min-width: 992px) {
        .icon-card .icon-card__icon .icon-card__sparkle {
          top: 64px;
          right: 33px; } }
      @media (min-width: 1260px) {
        .icon-card .icon-card__icon .icon-card__sparkle {
          top: 44px;
          right: 20px;
          width: 140px; } }

.icon-card-full .icon-card__header {
  position: relative;
  width: 100%;
  background: #E4FFF4;
  padding: 24px 32px 24px 130px;
  border-radius: 6px; }
  @media (min-width: 768px) {
    .icon-card-full .icon-card__header {
      padding: 28px 170px; } }
  .icon-card-full .icon-card__header .icon-card__icon {
    position: absolute;
    top: 50%;
    left: 25px;
    transform: translateY(-50%);
    width: 80px;
    z-index: 1; }
    .icon-card-full .icon-card__header .icon-card__icon img {
      vertical-align: middle; }
    @media (min-width: 768px) {
      .icon-card-full .icon-card__header .icon-card__icon {
        width: 94px; } }
    @media (min-width: 992px) {
      .icon-card-full .icon-card__header .icon-card__icon {
        width: 110px; } }
  .icon-card-full .icon-card__header .icon-card__sparkle {
    position: absolute;
    top: -33px;
    right: -17px;
    width: 90px; }
    @media (max-width: 440px) {
      .icon-card-full .icon-card__header .icon-card__sparkle {
        display: none; } }
    @media (min-width: 768px) {
      .icon-card-full .icon-card__header .icon-card__sparkle {
        top: -12px;
        right: 30px;
        width: 110px; } }
    @media (min-width: 992px) {
      .icon-card-full .icon-card__header .icon-card__sparkle {
        top: -12px;
        right: 50px; } }

.icon-card-full .icon-card__inner {
  background: #ffffff;
  padding: 24px 20px;
  margin-top: -6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 0 20px 20px 0px rgba(34, 51, 67, 0.1); }
  @media (min-width: 768px) {
    .icon-card-full .icon-card__inner {
      padding: 28px 170px; } }
  .icon-card-full .icon-card__inner p, .icon-card-full .icon-card__inner p a, .icon-card-full .icon-card__inner li {
    font-weight: 500; }
    @media (min-width: 768px) {
      .icon-card-full .icon-card__inner p, .icon-card-full .icon-card__inner p a, .icon-card-full .icon-card__inner li {
        font-size: 22px; } }

.information-block {
  padding: 32px 32px 60px 32px;
  background-color: #E4FFF4;
  background-image: url("data:image/svg+xml,%3Csvg width='970' height='154' viewBox='0 0 970 154' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath d='M1440 0v7713H0V0h1440Z'/%3E%3C/clipPath%3E%3CclipPath id='b'%3E%3Cpath d='M970 0v480H0V0h970Z'/%3E%3C/clipPath%3E%3CclipPath id='c'%3E%3Cpath d='M2170 0v898H0V0c0 242.133 485.77 438.42 1085 438.42S2170 242.133 2170 0Z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%23a)' transform='translate(-235 -5372)'%3E%3Cg clip-path='url(%23b)' transform='translate(235 5046)'%3E%3Cg clip-path='url(%23c)' transform='translate(-917 31)'%3E%3Cpath fill='%23A7D5C2' d='M917 0h970v449H917V0z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center bottom;
  border-radius: 8px; }
  @media (min-width: 992px) {
    .information-block {
      padding: 38px 48px 72px 48px; } }
  @media (min-width: 1260px) {
    .information-block {
      padding: 60px 60px 100px 60px; } }
  @media (max-width: 767px) {
    .information-block a {
      word-break: break-word; } }

.filter-bar {
  margin-top: 15px;
  background-color: #E4FFF4;
  border-radius: 6px;
  padding: 20px; }
  @media (min-width: 768px) {
    .filter-bar {
      padding: 20px 60px; }
      .filter-bar p {
        margin-bottom: 0; } }

.link, .link--fake {
  cursor: pointer;
  text-decoration: none;
  color: #223343;
  font-weight: 400;
  transition: color .3s; }

.link--icon span {
  margin-left: 10px; }

.link-arrow {
  display: inline-block;
  cursor: pointer;
  font-weight: 400;
  text-decoration: none;
  color: #223343;
  padding-right: 38px;
  background-position: right 1px center;
  background-size: 22px;
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(0 .255)' fill='none' fill-rule='evenodd'%3E%3Ccircle fill='%23A7D5C2' cx='11' cy='11' r='11'/%3E%3Cpath stroke='%23FFF' stroke-width='2' stroke-linecap='square' d='m9.125 7.125 3.75 3.75M13 11l-4 4'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  line-height: 1.5;
  opacity: 1;
  transition: all 0.3s; }
  @media (min-width: 992px) {
    .link-arrow:hover {
      opacity: 0.6;
      padding-right: 42px; } }

.link-arrow--primary-secondary {
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(0 .255)' fill='none' fill-rule='evenodd'%3E%3Ccircle fill='%2374A68E' cx='11' cy='11' r='11'/%3E%3Cpath stroke='%23FFF' stroke-width='2' stroke-linecap='square' d='m9.125 7.125 3.75 3.75M13 11l-4 4'/%3E%3C/g%3E%3C/svg%3E"); }

.link-arrow--orange {
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(0 .255)' fill='none' fill-rule='evenodd'%3E%3Ccircle fill='%23EA8A5D' cx='11' cy='11' r='11'/%3E%3Cpath stroke='%23FFF' stroke-width='2' stroke-linecap='square' d='m9.125 7.125 3.75 3.75M13 11l-4 4'/%3E%3C/g%3E%3C/svg%3E"); }

.link-arrow--green {
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(0 .255)' fill='none' fill-rule='evenodd'%3E%3Ccircle fill='%238A9366' cx='11' cy='11' r='11'/%3E%3Cpath stroke='%23FFF' stroke-width='2' stroke-linecap='square' d='m9.125 7.125 3.75 3.75M13 11l-4 4'/%3E%3C/g%3E%3C/svg%3E"); }

.notice {
  position: relative;
  background: #E4FFF4;
  border-left: 7px solid #A7D5C2;
  padding: 48px 30px;
  font-size: 2.6rem;
  margin-top: 40px; }
  .notice p:last-child {
    margin-bottom: 0; }
  .notice:after {
    content: '?';
    position: absolute;
    top: 0;
    left: 30px;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 2.6rem;
    line-height: 1;
    color: #ffffff;
    background: #A7D5C2;
    border-radius: 50%;
    padding: 10px 16px; }

.pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 40px; }
  .pagination .pagination__number, .pagination .pagination__current {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    transition: all 0.2s;
    text-decoration: none; }
  .pagination .pagination__number:hover {
    font-weight: bold; }
  .pagination .pagination__current {
    position: relative;
    font-weight: bold;
    color: #A7D5C2; }
    .pagination .pagination__current:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      height: 4px;
      background: #A7D5C2; }
  .pagination .pagination__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    margin-right: 8px;
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
    transition: all 0.2s;
    opacity: 1; }
    .pagination .pagination__arrow:hover {
      opacity: 0.5; }
    .pagination .pagination__arrow svg {
      fill: #333333;
      width: 22px;
      height: auto; }
  .pagination .pagination__dots {
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 20px; }

.pill {
  padding: 12px 5px;
  border-radius: 4px;
  font-size: 1.6rem;
  width: 110px;
  text-align: center; }

.services-block {
  position: relative;
  height: 100%;
  padding-bottom: 35px; }
  .services-block .link-arrow {
    position: absolute;
    bottom: 0;
    left: 0; }

.slider-gallery {
  position: relative; }
  .slider-gallery .slider__item {
    width: 75%;
    margin-right: 30px; }
  .slider-gallery .slider__controls {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 32px; }
    @media (min-width: 992px) {
      .slider-gallery .slider__controls {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(-140%);
        margin-bottom: 0; } }
    .slider-gallery .slider__controls .slider__control {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: none; }
      .slider-gallery .slider__controls .slider__control:focus, .slider-gallery .slider__controls .slider__control:active {
        outline: none; }
      .slider-gallery .slider__controls .slider__control--next {
        margin-left: 20px; }
      .slider-gallery .slider__controls .slider__control .icon > svg path {
        transition: fill 0.2s; }
      @media (min-width: 992px) {
        .slider-gallery .slider__controls .slider__control:hover .icon > svg path {
          fill: #A7D5C2 !important; } }

.slider-stories__container .slider__inner {
  display: flex;
  flex-wrap: wrap; }

.slider-stories__container .slider__content {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 32px; }
  @media (min-width: 992px) {
    .slider-stories__container .slider__content {
      width: 40%;
      padding-right: 40px; } }
  @media (min-width: 1260px) {
    .slider-stories__container .slider__content {
      width: 30%;
      padding-right: 70px; } }

.slider-stories__container .slider__controls {
  display: flex;
  justify-content: flex-start; }
  .slider-stories__container .slider__controls .slider__control {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: none; }
    .slider-stories__container .slider__controls .slider__control:focus, .slider-stories__container .slider__controls .slider__control:active {
      outline: none; }
    .slider-stories__container .slider__controls .slider__control .icon > svg path {
      transition: fill 0.2s; }
    .slider-stories__container .slider__controls .slider__control:hover .icon > svg path {
      fill: #E4FFF4 !important; }
    .slider-stories__container .slider__controls .slider__control:not(:last-child) {
      margin-right: 20px; }

@media (max-width: 991px) {
  .slider-stories__container {
    padding: 0 1.33334rem; } }

.slider-stories {
  position: relative;
  z-index: 3;
  width: 100%;
  margin-bottom: 30px; }
  .slider-stories .flickity-viewport {
    overflow: hidden;
    transition: height 0.2s;
    padding-top: 20px; }
  .slider-stories .slider__item {
    width: 100%;
    margin-top: 26px;
    margin-right: 30px;
    margin-bottom: 30px; }
    .slider-stories .slider__item .card {
      top: 0;
      box-shadow: none;
      transition: top .3s; }
      .slider-stories .slider__item .card:hover {
        top: -10px; }
    @media (min-width: 768px) {
      .slider-stories .slider__item {
        width: 49%; } }
    @media (min-width: 992px) {
      .slider-stories .slider__item {
        width: 400px; } }
    @media (min-width: 1260px) {
      .slider-stories .slider__item {
        width: 366px; } }
  .slider-stories:focus {
    outline: none; }
  @media (min-width: 992px) {
    .slider-stories {
      width: 60%;
      top: -26px; } }
  @media (min-width: 1260px) {
    .slider-stories {
      width: 70%; } }

.social-callout ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin-left: 0; }
  @media (max-width: 991px) {
    .social-callout ul li {
      width: 50%; } }
  @media (max-width: 480px) {
    .social-callout ul li {
      width: 100%; } }

.social-callout--orange .social-callout__item {
  background: #EA8A5D; }

.social-callout--green .social-callout__item {
  background: #8A9366; }

@media (min-width: 480px) {
  .social-callout {
    margin-left: -25px;
    margin-right: -25px; } }

.social-callout__item {
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  background: #A7D5C2;
  padding: 5px 20px 5px 5px;
  border-radius: 30px;
  text-decoration: none;
  opacity: 1;
  transition: all 0.3s; }
  .social-callout__item .icon {
    display: inline-block;
    margin-right: 20px; }
  .social-callout__item p {
    margin-bottom: 0; }
  @media (min-width: 992px) {
    .social-callout__item:hover {
      opacity: 0.8;
      top: -4px; } }
  @media (min-width: 480px) {
    .social-callout__item {
      margin-left: 25px;
      margin-right: 25px; } }

.timeline {
  position: relative;
  padding-bottom: 30px; }
  .timeline::before {
    content: "";
    width: 3px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #A7D5C2;
    border-radius: 3px; }
  .timeline::after {
    content: "";
    background: white;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, white 100%);
    height: 45px;
    width: 3px;
    position: absolute;
    bottom: 0; }
  @media (min-width: 768px) {
    .timeline::before {
      left: 50%;
      transform: translateX(-50%);
      right: initial; }
    .timeline::after {
      left: 50%;
      transform: translateX(-50%); }
    .timeline .timeline__row {
      display: flex;
      align-items: center;
      position: relative; }
      .timeline .timeline__row > * {
        flex: 1; }
      .timeline .timeline__row:first-of-type::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 50%;
        width: 10px;
        background: white; }
    .timeline .invis {
      padding: 20px;
      margin-bottom: 30px;
      margin-left: 40px; } }
  @media (min-width: 992px) {
    .timeline .invis {
      padding: 35px; }
    .timeline .timeline__row {
      margin-bottom: 40px; } }
  @media (min-width: 1260px) {
    .timeline .invis {
      padding: 35px;
      margin-left: 70px; } }

.timeline__block {
  background-color: #E4FFF4;
  padding: 20px;
  margin-bottom: 30px;
  margin-left: 40px;
  position: relative;
  border-radius: 6px; }
  .timeline__block::before {
    content: "";
    position: absolute;
    left: -68px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("/dist/img/icons/timeline-dot.svg");
    background-position: center;
    background-size: 58px 58px;
    height: 58px;
    width: 58px; }
  .timeline__block::after {
    content: "";
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15.5px 20px 15.5px 0;
    border-color: transparent #E4FFF4 transparent transparent; }
  .timeline__block p:last-of-type {
    margin-bottom: 0; }
  @media (min-width: 768px) {
    .timeline__block--even::before {
      content: none; }
    .timeline__block--odd {
      margin-left: 0;
      margin-right: 40px; }
      .timeline__block--odd::before {
        right: -68px;
        left: initial; }
      .timeline__block--odd::after {
        border-width: 15.5px 0 15.5px 20px;
        border-color: transparent transparent transparent #E4FFF4;
        left: 100%;
        right: initial; } }
  @media (min-width: 992px) {
    .timeline__block {
      padding: 35px; }
      .timeline__block--even {
        margin-left: 70px; }
      .timeline__block--odd {
        margin-left: 0;
        margin-right: 70px; }
        .timeline__block--odd::before {
          right: -98px;
          left: initial; } }

[data-tooltip] {
  position: relative;
  cursor: pointer; }

button[data-tooltip] {
  padding: 0; }

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
  z-index: 1; }

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-top: 8px;
  margin-left: -45px;
  padding: 9px 9px;
  width: 90px;
  border-radius: 10px;
  background-color: #60927b;
  color: #ffffff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-family: questa-sans, sans-serif; }

.tooltip--big {
  display: block; }

.tooltip--big[data-tooltip]:before {
  width: 130px;
  margin-left: -65px; }

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  top: 100%;
  left: 52%;
  margin-top: 3px;
  width: 0;
  border-bottom: 5px solid #60927b;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: ' ';
  font-size: 0;
  line-height: 0; }

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.copy-clipboard {
  display: inline-flex;
  align-items: center;
  background-color: #E4FFF4;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  padding: 8px 18px !important; }
  .copy-clipboard .icon {
    margin-left: 14px; }

.top-posts {
  height: 100%;
  background-color: #E4FFF4;
  padding: 20px;
  border-radius: 6px; }
  .top-posts ol {
    list-style: none;
    margin-left: 0;
    counter-reset: item; }
  .top-posts li {
    counter-increment: item;
    margin-bottom: 5px;
    position: relative;
    padding-left: 30px;
    padding-bottom: 20px; }
  .top-posts li:before {
    position: absolute;
    left: 0;
    top: -5px;
    line-height: 1;
    font-family: questa-sans, sans-serif;
    content: counter(item);
    font-size: 3rem;
    font-weight: bold;
    border-radius: 100%;
    color: inherit; }
  .top-posts a {
    color: inherit;
    text-decoration: none; }
    .top-posts a:hover {
      text-decoration: underline; }
  @media (min-width: 768px) {
    .top-posts {
      padding: 30px; } }

/*
  All a user has to do to change the calendar size is
  change the font-size on the container and everything
  magically resizes accordingly. Relative units ftw!
*/
.qs-datepicker-container {
  font-size: 1rem;
  font-family: sans-serif;
  color: black;
  position: absolute;
  width: 15.625em;
  display: flex;
  flex-direction: column;
  z-index: 9001;
  user-select: none;
  border: 1px solid gray;
  border-radius: 0.26392em;
  overflow: hidden;
  background: white;
  box-shadow: 0 1.25em 1.25em -0.9375em rgba(0, 0, 0, 0.3); }
  .qs-datepicker-container * {
    box-sizing: border-box; }

.qs-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.qs-hidden {
  display: none; }

.qs-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  color: white;
  width: 100%;
  height: 100%;
  padding: .5em;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s;
  display: flex;
  flex-direction: column; }
  .qs-overlay.qs-hidden {
    opacity: 0;
    z-index: -1; }
  .qs-overlay .qs-overlay-year {
    border: none;
    background: transparent;
    border-bottom: 1px solid white;
    border-radius: 0;
    color: white;
    font-size: 0.875em;
    padding: .25em 0;
    width: 80%;
    text-align: center;
    margin: 0 auto;
    display: block; }
    .qs-overlay .qs-overlay-year::-webkit-inner-spin-button {
      -webkit-appearance: none; }
  .qs-overlay .qs-close {
    padding: .5em;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0; }
  .qs-overlay .qs-submit {
    border: 1px solid white;
    border-radius: 0.26392em;
    padding: .5em;
    margin: 0 auto auto;
    cursor: pointer;
    background: rgba(128, 128, 128, 0.4); }
    .qs-overlay .qs-submit.qs-disabled {
      color: gray;
      border-color: gray;
      cursor: not-allowed; }
  .qs-overlay .qs-overlay-month-container {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1; }
  .qs-overlay .qs-overlay-month {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% / 3);
    cursor: pointer;
    opacity: .5;
    transition: opacity 0.15s; }
    .qs-overlay .qs-overlay-month.active, .qs-overlay .qs-overlay-month:hover {
      opacity: 1; }

.qs-controls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  background: lightgray;
  filter: blur(0px);
  transition: filter 0.3s; }
  .qs-controls.qs-blur {
    filter: blur(5px); }

.qs-arrow {
  height: 1.5625em;
  width: 1.5625em;
  position: relative;
  cursor: pointer;
  border-radius: 0.26392em;
  transition: background .15s; }
  .qs-arrow:hover {
    background: rgba(0, 0, 0, 0.1); }
    .qs-arrow:hover.qs-left:after {
      border-right-color: black; }
    .qs-arrow:hover.qs-right:after {
      border-left-color: black; }
  .qs-arrow:after {
    content: '';
    border: 0.39062em solid transparent;
    position: absolute;
    top: 50%;
    transition: border .2s; }
  .qs-arrow.qs-left:after {
    border-right-color: gray;
    right: 50%;
    transform: translate(25%, -50%); }
  .qs-arrow.qs-right:after {
    border-left-color: gray;
    left: 50%;
    transform: translate(-25%, -50%); }

.qs-month-year {
  font-weight: bold;
  transition: border .2s;
  border-bottom: 1px solid transparent;
  cursor: pointer; }
  .qs-month-year:hover {
    border-bottom: 1px solid gray; }
  .qs-month-year:focus, .qs-month-year:active:focus {
    outline: none; }

.qs-month {
  padding-right: .5ex; }

.qs-year {
  padding-left: .5ex; }

.qs-squares {
  display: flex;
  flex-wrap: wrap;
  padding: 0.3125em;
  filter: blur(0px);
  transition: filter 0.3s; }
  .qs-squares.qs-blur {
    filter: blur(5px); }

.qs-square {
  width: calc(100% / 7);
  height: 1.5625em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background .1s;
  border-radius: 0.26392em; }
  .qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover {
    background: orange; }

.qs-current {
  font-weight: bold;
  text-decoration: underline; }

/*
  3 possibilities:
    1. Single, active date.
    2. Daterange start selection.
    3. Daterange end selection.
*/
.qs-active,
.qs-range-start,
.qs-range-end {
  background: lightblue; }

.qs-range-start:not(.qs-range-6) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.qs-range-middle {
  background: #d4ebf2; }
  .qs-range-middle:not(.qs-range-0):not(.qs-range-6) {
    border-radius: 0; }
  .qs-range-middle.qs-range-0 {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .qs-range-middle.qs-range-6 {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.qs-range-end:not(.qs-range-0) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.qs-disabled,
.qs-outside-current-month {
  opacity: .2; }

.qs-disabled {
  cursor: not-allowed; }

.qs-empty {
  cursor: default; }

.qs-day {
  cursor: default;
  font-weight: bold;
  color: gray; }

.qs-event {
  position: relative; }
  .qs-event:after {
    content: '';
    position: absolute;
    width: 0.46875em;
    height: 0.46875em;
    border-radius: 50%;
    background: #07f;
    bottom: 0;
    right: 0; }

/*! Flickity v2.3.0
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: white;
  cursor: pointer; }

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F; }

.flickity-button:active {
  opacity: 0.6; }

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

.flickity-button-icon {
  fill: currentColor; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

.glightbox-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999 !important;
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: none; }

.glightbox-container.inactive {
  display: none; }

.glightbox-container .gcontainer {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden; }

.glightbox-container .gslider {
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.glightbox-container .gslide {
  width: 100%;
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0; }

.glightbox-container .gslide.current {
  opacity: 1;
  z-index: 99999;
  position: relative; }

.glightbox-container .gslide.prev {
  opacity: 1;
  z-index: 9999; }

.glightbox-container .gslide-inner-content {
  width: 100%; }

.glightbox-container .ginner-container {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 100%;
  margin: auto;
  height: 100vh; }

.glightbox-container .ginner-container.gvideo-container {
  width: 100%; }

.glightbox-container .ginner-container.desc-bottom,
.glightbox-container .ginner-container.desc-top {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

.glightbox-container .ginner-container.desc-left,
.glightbox-container .ginner-container.desc-right {
  max-width: 100% !important; }

.gslide iframe,
.gslide video {
  outline: none !important;
  border: none;
  min-height: 165px;
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: auto;
  touch-action: auto; }

.gslide:not(.current) {
  pointer-events: none; }

.gslide-image {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.gslide-image img {
  max-height: 100vh;
  display: block;
  padding: 0;
  float: none;
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: 100vw;
  width: auto;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  -ms-touch-action: none;
  touch-action: none;
  margin: auto;
  min-width: 200px; }

.desc-top .gslide-image img,
.desc-bottom .gslide-image img {
  width: auto; }

.desc-left .gslide-image img,
.desc-right .gslide-image img {
  width: auto;
  max-width: 100%; }

.gslide-image img.zoomable {
  position: relative; }

.gslide-image img.dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
  -webkit-transition: none;
  transition: none; }

.gslide-video {
  position: relative;
  max-width: 100vh;
  width: 100% !important; }

.gslide-video .plyr__poster-enabled.plyr--loading .plyr__poster {
  display: none; }

.gslide-video .gvideo-wrapper {
  width: 100%;
  /* max-width: 160vmin; */
  margin: auto; }

.gslide-video::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0.34);
  display: none; }

.gslide-video.playing::before {
  display: none; }

.gslide-video.fullscreen {
  max-width: 100% !important;
  min-width: 100%;
  height: 75vh; }

.gslide-video.fullscreen video {
  max-width: 100% !important;
  width: 100% !important; }

.gslide-inline {
  background: #fff;
  text-align: left;
  max-height: calc(100vh - 40px);
  overflow: auto;
  max-width: 100%;
  margin: auto; }

.gslide-inline .ginlined-content {
  padding: 20px;
  width: 100%; }

.gslide-inline .dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
  -webkit-transition: none;
  transition: none; }

.ginlined-content {
  overflow: auto;
  display: block !important;
  opacity: 1; }

.gslide-external {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-width: 100%;
  background: #fff;
  padding: 0;
  overflow: auto;
  max-height: 75vh;
  height: 100%; }

.gslide-media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto; }

.zoomed .gslide-media {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.desc-top .gslide-media,
.desc-bottom .gslide-media {
  margin: 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

.gslide-description {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%; }

.gslide-description.description-left,
.gslide-description.description-right {
  max-width: 100%; }

.gslide-description.description-bottom,
.gslide-description.description-top {
  margin: 0 auto;
  width: 100%; }

.gslide-description p {
  margin-bottom: 12px; }

.gslide-description p:last-child {
  margin-bottom: 0; }

.zoomed .gslide-description {
  display: none; }

.glightbox-button-hidden {
  display: none; }

/*
 * Description for mobiles
 * something like facebook does the description
 * for the photos
*/
.glightbox-mobile .glightbox-container .gslide-description {
  height: auto !important;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 19px 11px;
  max-width: 100vw !important;
  -webkit-box-ordinal-group: 3 !important;
  -ms-flex-order: 2 !important;
  order: 2 !important;
  max-height: 78vh;
  overflow: auto !important;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.75)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  padding-bottom: 50px; }

.glightbox-mobile .glightbox-container .gslide-title {
  color: #fff;
  font-size: 1em; }

.glightbox-mobile .glightbox-container .gslide-desc {
  color: #a1a1a1; }

.glightbox-mobile .glightbox-container .gslide-desc a {
  color: #fff;
  font-weight: bold; }

.glightbox-mobile .glightbox-container .gslide-desc * {
  color: inherit; }

.glightbox-mobile .glightbox-container .gslide-desc .desc-more {
  color: #fff;
  opacity: 0.4; }

.gdesc-open .gslide-media {
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  opacity: 0.4; }

.gdesc-open .gdesc-inner {
  padding-bottom: 30px; }

.gdesc-closed .gslide-media {
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  opacity: 1; }

.greset {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }

.gabsolute {
  position: absolute; }

.grelative {
  position: relative; }

.glightbox-desc {
  display: none !important; }

.glightbox-open {
  overflow: hidden; }

.gloader {
  height: 25px;
  width: 25px;
  -webkit-animation: lightboxLoader 0.8s infinite linear;
  animation: lightboxLoader 0.8s infinite linear;
  border: 2px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  position: absolute;
  display: block;
  z-index: 9999;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 47%; }

.goverlay {
  width: 100%;
  height: calc(100vh + 1px);
  position: fixed;
  top: -1px;
  left: 0;
  background: #000;
  will-change: opacity; }

.glightbox-mobile .goverlay {
  background: #000; }

.gprev,
.gnext,
.gclose {
  z-index: 99999;
  cursor: pointer;
  width: 26px;
  height: 44px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

.gprev svg,
.gnext svg,
.gclose svg {
  display: block;
  width: 25px;
  height: auto;
  margin: 0;
  padding: 0; }

.gprev.disabled,
.gnext.disabled,
.gclose.disabled {
  opacity: 0.1; }

.gprev .garrow,
.gnext .garrow,
.gclose .garrow {
  stroke: #fff; }

.gbtn.focused {
  outline: 2px solid #0f3d81; }

iframe.wait-autoplay {
  opacity: 0; }

.glightbox-closing .gnext,
.glightbox-closing .gprev,
.glightbox-closing .gclose {
  opacity: 0 !important; }

/*Skin */
.glightbox-clean .gslide-description {
  background: #fff; }

.glightbox-clean .gdesc-inner {
  padding: 22px 20px; }

.glightbox-clean .gslide-title {
  font-size: 1em;
  font-weight: normal;
  font-family: arial;
  color: #000;
  margin-bottom: 19px;
  line-height: 1.4em; }

.glightbox-clean .gslide-desc {
  font-size: 0.86em;
  margin-bottom: 0;
  font-family: arial;
  line-height: 1.4em; }

.glightbox-clean .gslide-video {
  background: #000; }

.glightbox-clean .gprev,
.glightbox-clean .gnext,
.glightbox-clean .gclose {
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px; }

.glightbox-clean .gprev path,
.glightbox-clean .gnext path,
.glightbox-clean .gclose path {
  fill: #fff; }

.glightbox-clean .gprev {
  position: absolute;
  top: -100%;
  left: 30px;
  width: 40px;
  height: 50px; }

.glightbox-clean .gnext {
  position: absolute;
  top: -100%;
  right: 30px;
  width: 40px;
  height: 50px; }

.glightbox-clean .gclose {
  width: 35px;
  height: 35px;
  top: 15px;
  right: 10px;
  position: absolute; }

.glightbox-clean .gclose svg {
  width: 18px;
  height: auto; }

.glightbox-clean .gclose:hover {
  opacity: 1; }

/*CSS Animations*/
.gfadeIn {
  -webkit-animation: gfadeIn 0.5s ease;
  animation: gfadeIn 0.5s ease; }

.gfadeOut {
  -webkit-animation: gfadeOut 0.5s ease;
  animation: gfadeOut 0.5s ease; }

.gslideOutLeft {
  -webkit-animation: gslideOutLeft 0.3s ease;
  animation: gslideOutLeft 0.3s ease; }

.gslideInLeft {
  -webkit-animation: gslideInLeft 0.3s ease;
  animation: gslideInLeft 0.3s ease; }

.gslideOutRight {
  -webkit-animation: gslideOutRight 0.3s ease;
  animation: gslideOutRight 0.3s ease; }

.gslideInRight {
  -webkit-animation: gslideInRight 0.3s ease;
  animation: gslideInRight 0.3s ease; }

.gzoomIn {
  -webkit-animation: gzoomIn 0.5s ease;
  animation: gzoomIn 0.5s ease; }

.gzoomOut {
  -webkit-animation: gzoomOut 0.5s ease;
  animation: gzoomOut 0.5s ease; }

@-webkit-keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes gfadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes gfadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes gfadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes gfadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes gslideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0); }
  to {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes gslideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0); }
  to {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@-webkit-keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden; } }

@keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden; } }

@-webkit-keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@-webkit-keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    opacity: 0; } }

@keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    opacity: 0; } }

@-webkit-keyframes gzoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 1; } }

@keyframes gzoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 1; } }

@-webkit-keyframes gzoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

@keyframes gzoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

@media (min-width: 769px) {
  .glightbox-container .ginner-container {
    width: auto;
    height: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
  .glightbox-container .ginner-container.desc-top .gslide-description {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .glightbox-container .ginner-container.desc-top .gslide-image,
  .glightbox-container .ginner-container.desc-top .gslide-image img {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .glightbox-container .ginner-container.desc-left .gslide-description {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .glightbox-container .ginner-container.desc-left .gslide-image {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .gslide-image img {
    max-height: 97vh;
    max-width: 100%; }
  .gslide-image img.zoomable {
    cursor: -webkit-zoom-in;
    cursor: zoom-in; }
  .zoomed .gslide-image img.zoomable {
    cursor: -webkit-grab;
    cursor: grab; }
  .gslide-inline {
    max-height: 95vh; }
  .gslide-external {
    max-height: 100vh; }
  .gslide-description.description-left,
  .gslide-description.description-right {
    max-width: 275px; }
  .glightbox-open {
    height: auto; }
  .goverlay {
    background: rgba(0, 0, 0, 0.92); }
  .glightbox-clean .gslide-media {
    -webkit-box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.65);
    box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.65); }
  .glightbox-clean .description-left .gdesc-inner,
  .glightbox-clean .description-right .gdesc-inner {
    position: absolute;
    height: 100%;
    overflow-y: auto; }
  .glightbox-clean .gprev,
  .glightbox-clean .gnext,
  .glightbox-clean .gclose {
    background-color: rgba(0, 0, 0, 0.32); }
  .glightbox-clean .gprev:hover,
  .glightbox-clean .gnext:hover,
  .glightbox-clean .gclose:hover {
    background-color: rgba(0, 0, 0, 0.7); }
  .glightbox-clean .gprev {
    top: 45%; }
  .glightbox-clean .gnext {
    top: 45%; } }

@media (min-width: 992px) {
  .glightbox-clean .gclose {
    opacity: 0.7;
    right: 20px; } }

@media screen and (max-height: 420px) {
  .goverlay {
    background: #000; } }
